<script setup>

import MainFooter from "@/components/partial/MainFooter.vue";
import {computed, ref} from "vue";
import {Dictionary, Funds, Rgo} from "@/common/api.service";
import {useRoute, useRouter} from "vue-router";

const route = useRoute()
const router = useRouter()
const item = ref({
  tranches: []
})
const ownPrograms = ref([])
const program = ref({})
const fund = ref({})
const isLoading = ref(false)
const counterparties = ref([])
const counterparty = ref([])
const supportedIndustries = ref([])

const counterpartyList = computed(() => {
  let r = []
  counterparties.value.forEach(function (f) {
    r.push({value: f.value, label: `${f.label}`})
  })
  return r
})
const supportedIndustriesList = computed(() => {
  let r = []
  supportedIndustries.value.forEach(function (f) {
    r.push({value: f.value, label: `${f.title}`})
  })

  return r
})

await Funds.item(route.params.id)
    .then(({data}) => {
      fund.value = data
    }).catch(() => {
    })

await Funds.ownPrograms(route.params.id)
    .then(({data}) => {
      ownPrograms.value = data
      if (data.length > 0) {
        program.value = data[0]
      }
    }).catch(() => {
    })

await Dictionary.supportedIndustries()
    .then(({data}) => {
      supportedIndustries.value = data
      if (data.length > 0) {
        item.value.okved = data[0]
      }
    }).catch(() => {
    })

async function counterpartySearch(query) {
  isLoading.value = true
  counterparties.value = []
  if (query === "") {
    isLoading.value = false
    return
  }
  await Rgo.counterparties(query).then(({data}) => {
    data.forEach(function (f) {
      counterparties.value.push({value: f, label: `${f.title} ИНН: ${f.inn} ОГРН: ${f.ogrn}`})
    })
  })
  isLoading.value = false
}

async function saveOwnProgram() {
  item.value.counerpartyId = counterparty.value.value
  await Funds.ownProgramsCreateAgreement(program.value.id, item.value).then(async () => {
    await router.push({name: "ListAgreements", params: {id: fund.value.region.id}});
  })
}

</script>

<template>
  <div class="wrapper">
    <MainHeader></MainHeader>
    <main class="page page--js">
      <div class="breadcrumbs-container">
        <div class="container">
          <div class="breadcrumbs-wrapper">
            <ul class="breadcrumbs">
              <li>
                <router-link
                    :to="{name: 'ListRegions'}">Модуль регионов
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{name: 'ListRegions'}">Список регионов
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{ name: 'ListAgreementsByRegion', params: { id: fund.id } }">Договоры займа
                </router-link>
              </li>
              <li>Добавление договора займа
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="content__wrapper">
            <div class="content__contract">
              <div class="content__title content__title--contract">
                <h1 class="title title-h1--contract">Добавление договора займа</h1>
              </div>
              <form action="#" class="contract">
                <div class="contract__row">
                  <div class="contract__item">
                    <label for="number-contract" class="title-field">Номер договора займа*
                      <input class="field" type="text" name="number-contract" v-model="item.agreementNumber"
                             placeholder="Введите номер договора займа">
                    </label>
                    <label for="organisation-name" class="title-field">Название организации*
                      <Multiselect
                          class="c-input c-select c-filter__input c-multiselect-filter"
                          v-model="counterparty"
                          mode="single"
                          :loading="isLoading"
                          @search-change="counterpartySearch"
                          :min-chars="10"
                          :show-no-results="false"
                          :searchable="true"
                          :options="counterpartyList"
                          placeholder="РГО"
                          :no-options-text="'Укажите ОГРН или ИНН'"
                      />
                    </label>
                    <label for="project-name" class="title-field">Наименование проекта*
                      <textarea class="field field--textarea" v-model="item.projectTitle" name="project-name"
                                id="" placeholder="Введите наименование проекта"></textarea>
                    </label>
                    <label for="program-rfrp" class="title-field">Программа РФРП*
                      <select name="program-rfrp" v-model="program" class="field field--select">
                        <option :value="i" v-for="i in ownPrograms" :key="i.id">{{i.title}}</option>
                      </select>
                    </label>
                    <label for="special-purpose" class="title-field">На какие цели предоставлен
                      займ*
                      <input class="field" type="text" name="special-purpose"  v-model="item.targetDescription"
                             placeholder="Введите цель займа">
                    </label>
                    <label for="project-code" class="title-field">ОКВЭД проекта*
                      <Multiselect
                          placeholder=""
                          class="field field--select" style="height: auto"
                          v-model="item.okved"
                          mode="single"
                          :searchable="true"
                          :aria-expanded="true"
                          :options="supportedIndustriesList"
                      />
                    </label>
                    <label for="contract-date" class="title-field">Дата договора займа*
                      <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                                     v-model="item.agreementDate"/>
                    </label>
                    <label for="transfer-date" class="title-field">Дата перечисления средств*
                      <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                                     v-model="item.fundsTransferDate"/>
                    </label>
                    <label for="comment" class="title-field">Комментарий
                      <textarea class="field field--textarea" type="text" name="comment" v-model="item.comment"
                                placeholder="Введите комментарий"></textarea>
                    </label>
                  </div>
                  <div class="contract__item">
                    <label for="sum-contract" class="title-field">Сумма займа, млн руб.*
                      <input class="field" type="text" name="sum-contract" v-model="item.loanSum"
                             placeholder="Введите сумму займа">
                    </label>
                    <label for="co-financing" class="title-field">Софинансирование (частные
                      инвестиции),
                      млн руб.*
                      <input class="field" type="text" name="co-financing" v-model="item.cofinanceSum"
                             placeholder="Введите условия софинансирования">
                    </label>
                    <label for="project-budget" class="title-field">Общий бюджет проекта, млн руб.*
                      <input class="field" type="text" name="project-budget" v-model="item.totalBudgetOfProject"
                             placeholder="Введите бюджет проекта в млн руб.">
                    </label>
                    <label for="project-revenue" class="title-field">Суммарный объем выручки по
                      проекту,
                      млн руб.*
                      <input class="field" type="text" name="project-revenue" v-model="item.summaryGainOfProject"
                             placeholder="Введите суммарный объем выручки в млн руб.">
                    </label>
                    <label for="tax-revenues" class="title-field">Объем налоговых поступлений, млн
                      руб.*
                      <input class="field" type="text" name="tax-revenues" v-model="item.summaryTaxRevenue"
                             placeholder="Введите объем налоговых поступлений в млн руб.">
                    </label>
                    <label for="number-applications" class="title-field">Кол-во заявок на объекты
                      интеллектуальной собственности, шт.*
                      <input class="field" type="text" name="number-applications" v-model="item.applicationsForIntellectualPropertyObjectQuantity"
                             placeholder="Введите количество заявок">
                    </label>
                    <label for="number-workplaces" class="title-field">Кол-во высокопроизв. рабочих
                      мест, шт.*
                      <input class="field" type="text" name="number-applications" v-model="item.highPerformanceWorkbenchQuantity"
                             placeholder="Введите количество рабочих мест">
                    </label>

                  </div>
                </div>
                <div class="title title--contract-subtitle">Обеспечение</div>
                <div class="contract__row">
                  <div class="contract__item">
                    <label for="bank-guarantee" class="title-field">Банковская гарантия, млн руб.
                      <input class="field" type="text" name="bank-guarantee" v-model="item.bankGuarantee"
                             placeholder="Введите значение в млн руб.">
                    </label>
                    <label for="guarantee-ul" class="title-field">Поручительство ЮЛ, млн руб.
                      <input class="field" type="text" name="bank-guarantee" v-model="item.legalEntityGuarantee"
                             placeholder="Введите значение в млн руб.">
                    </label>
                    <label for="guarantee-fl" class="title-field">Поручительство физ. лиц, млн руб.
                      <input class="field" type="text" name="guarantee-fl" v-model="item.individualGuarantee"
                             placeholder="Введите значение в млн руб.">
                    </label>
                    <label for="guarantee-msp" class="title-field">Поручительство корпорации МСП,
                      млн руб.
                      <input class="field" type="text" name="guarantee-msp" v-model="item.mspCorporationGuarantee"
                             placeholder="Введите значение в млн руб.">
                    </label>
                  </div>
                  <div class="contract__item">
                    <label for="rgo" class="title-field">РГО, млн руб.
                      <input class="field" type="text" name="rgo" v-model="item.rgoGuarantee"
                             placeholder="Введите значение в млн руб.">
                    </label>
                    <label for="real-estate" class="title-field">Недвижимость, млн руб.
                      <input class="field" type="text" name="real-estate" v-model="item.realEstateGuarantee"
                             placeholder="Введите значение в млн руб.">
                    </label>
                    <label for="equipment-transport" class="title-field">Оборудование и транспорт,
                      млн руб.
                      <input class="field" type="text" name="equipment-transport" v-model="item.equipmentAndTransportGuarantee"
                             placeholder="Введите значение в млн руб.">
                    </label>
                    <label for="other" class="title-field">Оборудование и транспорт, млн руб.
                      <input class="field" type="text" name="other" v-model="item.securitiesAndOtherGuarantee">
                    </label>
                  </div>
                </div>
                <div class="tranche-financing">
                  <div class="title title--tranche-financing">Траншевое финансирование<button
                      type="button" class="button-add" @click="item.tranches.push({})"></button>
                  </div>
                  <div class="table-container table-container--pad-inline">
                    <table class="table table--table-mini">
                      <thead>
                      <tr>
                        <th>Дата</th>
                        <th>Сумма, млн. руб.</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(i, index) in item.tranches" :key="i">
                        <td>
                          <VueDatePicker
                              model-type="yyyy-MM-dd"
                              placeholder="00.00.0000"
                              class="field field--calendar"
                              v-model="i.date"/>
                        </td>
                        <td>
                          <input class="field" type="text" name="rgo" v-model="i.sum"
                                 placeholder="Введите значение в млн руб.">
                        </td>
                        <td>
                          <div class="table-button-block"><button type="button" @click="item.tranches.splice(index,1)"
                                                                  class="button-del"></button></div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="buttons-block buttons-block--right">

                  <router-link
                      class="button button--clear"
                      :to="{ name: 'ListAgreementsByRegion', params: { id: fund.id } }">Отмена изменений
                  </router-link>
                  <button type="button" class="button" @click="saveOwnProgram">сохранить</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="del-document">
        <div class="popup popup-del">
          <div class="modal-title">Удаление записи</div>
          <div class="modal-body">Вы действительно хотите удалить самостоятельную программу?</div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear close-modal--js">Закрыть</button>
              <button type="button" class="button button--delete sub-modal-open--js"
                      data-modal="close-document">Удалить</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="close-document">
        <div class="popup">
          <div class="modal-title">Запись удалена</div>
          <div class="modal-body"></div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button close-modal--js">Закрыть</button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped>

</style>