<script setup>

import MainFooter from "@/components/partial/MainFooter.vue";
import {Regions, Dictionary, Funds, FileDocument, Rgo} from "@/common/api.service";
import {computed, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import Shared from "@/common/shared";
import {useDropzone} from "vue3-dropzone";
import moment from "moment";

const route = useRoute()
const router = useRouter()

const item = ref({
  statistics: {},
  fund: {
    region: {
      id: ""
    }
  },
  responsibilities: [],
  fundContacts: [],
  capitalization: {},
  capitalizationStat: {},
  collabPrograms: [],
  ownPrograms: [],
  ownProgramsAgreements: [],
  fundDocuments: [],
  rgoDocuments: [],
  regionDocuments: [],
  rgo: {
    notCreated: true
  },
  agreementStatistics: {},
  npaStatistics: {},
  npaContacts: [],
  npaRegistry: []
});
const fund = ref({})
const permissions = ref([])
const legalForms = ref([])
const collabPrograms = ref([])
const projectManagers = ref([])
const regionalExperts = ref([])
const responsibilities = ref({
  projectManagerId: "",
  regionalExpertId: ""
})
const collab = ref({})
const docFile = ref()
const uploadDocument = ref({})
const hideRfrpInfo = ref(true)
const hideRgoInfo = ref(true)
const hideRgoDocs = ref(true)
const hideRfrpContacts = ref(true)
const hideRgoContacts = ref(true)
const contact = ref({})
const isLoading = ref(false)
const counterparties = ref([])
const rgoAgreementStatuses = ref([])
const rgoAccreditations = ref([])
const rgoForm2021Statuses = ref([])
const rgoStatus = ref({})

const counterpartyList = computed(() => {
  let r = []
  counterparties.value.forEach(function (f) {
    r.push({value: f.value, label: `${f.label}`})
  })
  return r
})

const {getRootProps, getInputProps} = useDropzone({onDrop});

function onDrop(acceptFiles) {
  docFile.value = acceptFiles[0]
}

async function onAddDocFile(e) {
  let files = e.target.files;
  for (let i = 0; i < files.length; i++) {
    let file = files[i];
    docFile.value = file
  }
}

async function counterpartySearch(query) {
  isLoading.value = true
  counterparties.value = []
  if (query === "") {
    isLoading.value = false
    return
  }
  await Rgo.counterparties(query).then(({data}) => {
    data.forEach(function (f) {
      counterparties.value.push({value: f, label: `${f.title} ИНН: ${f.inn} ОГРН: ${f.ogrn}`})
    })
  })
  isLoading.value = false
}

async function loadItem(fund) {
  await Funds.item(fund)
      .then(({data}) => {
        item.value.fund = data
      }).catch(() => {
      })
  await Regions.statistics(item.value.fund.region.id)
      .then(({data}) => {
        item.value.statistics = data
      }).catch(() => {
      })
  await Regions.responsibilities(item.value.fund.region.id)
      .then(({data}) => {
        item.value.responsibilities = data
      }).catch(() => {
      })
  await Funds.contacts(fund)
      .then(({data}) => {
        item.value.fundContacts = data
      }).catch(() => {
      })
  await Funds.capitalization(fund)
      .then(({data}) => {
        item.value.capitalization = data
      }).catch(() => {
      })
  await Funds.capitalizationStatistics(fund)
      .then(({data}) => {
        item.value.capitalizationStat = data
      }).catch(() => {
      })
  await Funds.collabPrograms(fund)
      .then(({data}) => {
        item.value.collabPrograms = data
      }).catch(() => {
      })
  await Funds.ownPrograms(fund)
      .then(({data}) => {
        item.value.ownPrograms = data
      }).catch(() => {
      })
  await Funds.ownProgramsAgreements(fund)
      .then(({data}) => {
        item.value.ownProgramsAgreements = data
      }).catch(() => {
      })
  await Funds.documents(fund)
      .then(({data}) => {
        item.value.fundDocuments = data
      }).catch(() => {
      })
  await Rgo.item(item.value.fund.region.id)
      .then(({data}) => {
        item.value.rgo = data
        item.value.rgo.notCreated = false
      }).catch(() => {
      })
  if (!item.value.notCreated) {
    await Rgo.documents(item.value.rgo.id)
        .then(({data}) => {
          item.value.rgoDocuments = data
        }).catch(() => {
        })
  }

  await Funds.agreementStatistics(fund)
      .then(({data}) => {
        item.value.agreementStatistics = data
      }).catch(() => {
      })
  await Regions.documents(item.value.fund.region.id)
      .then(({data}) => {
        item.value.regionDocuments = data
      }).catch(() => {
      })
  await Regions.npaStatistics(item.value.fund.region.id)
      .then(({data}) => {
        item.value.npaStatistics = data
      }).catch(() => {
      })
  await Regions.npaRegistry().then(({data}) => {
    item.value.npaRegistry = data.filter(i => i.regionId === item.value.fund.region.id)
  }).catch(() => {
  })
  item.value.npaContacts = []
  item.value.npaRegistry.forEach(function (f) {
    Regions.npaContacts(f.id)
        .then(({data}) => {
          data.forEach(function (i) {
            i.regulationId = f.id
            item.value.npaContacts.push(i)
          })
        }).catch(() => {
    })
  })
  await Dictionary.rgoAccreditations()
      .then(({data}) => {
        rgoAccreditations.value = data
      }).catch(() => {
      })
  await Dictionary.rgoAgreementStatuses()
      .then(({data}) => {
        rgoAgreementStatuses.value = data
      }).catch(() => {
      })
  await Dictionary.rgoForm2021Statuses()
      .then(({data}) => {
        rgoForm2021Statuses.value = data
      }).catch(() => {
      })
}

await Regions.permissions()
    .then(async ({data}) => {
      permissions.value = data
      await loadItem(route.params.fund)
    }).catch(async () => {
      await router.push({name: "Error"});
    })

function permission(permission) {
  return permissions.value.find((p) => p === permission)
}

async function showMainRFRP() {
  fund.value = item.value.fund

  await Dictionary.legalForms()
      .then(({data}) => {
        legalForms.value = data
      }).catch(() => {
      })
  Shared.showModal('#edit-main-rfrp')
}

async function saveMainRFRP() {
  await Funds.updateFunds(route.params.fund, fund.value)
  await Funds.item(route.params.fund)
      .then(({data}) => {
        item.value.fund = data
      }).catch(() => {
      })
  Shared.closeModal('#edit-main-rfrp')
}

async function showMainRGO() {
  fund.value = item.value.rgo

  Shared.showModal('#edit-main-rgo')
}

async function saveMainRGO() {
  await Rgo.update(item.value.fund.region.id, fund.value.id, fund.value)
  Shared.closeModal('#edit-main-rgo')
}

async function showCreateMainRGO() {
  fund.value = {
    "counterpartyId": "",
    "siteUrl": "",
    "generalEmail": "",
    "generalPhone": "",
    "loanLimit": 0,
    "suppliedLoansCount": 0,
    "rgoGuaranteeAmounts": [
      {
        "key": new Date().getFullYear(),
        "value": 0
      }
    ],
    "address": "",
    "ogrn": ""
  }

  Shared.showModal('#create-main-rgo')
}

async function saveCreateMainRGO() {
  fund.value.ogrn =
      await Rgo.create(item.value.fund.region.id, {
        "counterpartyId": fund.value.counterparty.id,
        "siteUrl": fund.value.siteUrl,
        "generalEmail": fund.value.generalEmail,
        "generalPhone": fund.value.generalPhone,
        "loanLimit": fund.value.loanLimit,
        "suppliedLoansCount": fund.value.suppliedLoansCount,
        "rgoGuaranteeAmounts": [
          {
            "key": 2025,
            "value": fund.value.rgoGuaranteeAmounts[0].value
          }
        ],
        "address": "address",
        "ogrn": fund.value.counterparty.ogrn
      })
  Shared.closeModal('#create-main-rgo')
}

async function showResponsibilities() {
  responsibilities.value.projectManagerId = item.value.responsibilities.projectManager.id
  responsibilities.value.regionalExpertId = item.value.responsibilities.regionalExpert.id

  await Regions.projectManagers()
      .then(({data}) => {
        projectManagers.value = data
      }).catch(() => {
      })
  await Regions.regionalExperts()
      .then(({data}) => {
        regionalExperts.value = data
      }).catch(() => {
      })

  Shared.showModal('#responsible-person')
}

async function saveResponsibilities() {
  await Regions.updateResponsibilities(item.value.fund.region.id, responsibilities.value)
  await Regions.responsibilities(item.value.fund.region.id)
      .then(({data}) => {
        item.value.responsibilities = data
      }).catch(() => {
      })
  Shared.closeModal('#responsible-person')
}

async function showAddCollabPrograms() {
  await Dictionary.collabPrograms()
      .then(({data}) => {
        collabPrograms.value = data
      }).catch(() => {
      })

  collab.value = {}
  Shared.showModal('#add-joint-programs')
}

async function showAddContact() {
  contact.value = {}
  Shared.showModal('#add-contact')
}

async function showNPAAddContact() {
  contact.value = {}
  await Regions.npaRegistry().then(({data}) => {
    item.value.npaRegistry = data.filter(i => i.regionId === item.value.fund.region.id)
    Shared.showModal('#add-contact-npa')
  }).catch(() => {
  })
}

async function saveNewContact() {
  await Rgo.createContact(item.value.rgo.id, contact.value)
  await Rgo.item(item.value.fund.region.id)
      .then(({data}) => {
        item.value.rgo = data
        item.value.rgo.notCreated = false
      }).catch(() => {
      })
  Shared.closeModal('#add-contact')
}

async function saveNPANewContact() {
  await Regions.createNPAContact(contact.value.npa.id, contact.value)
  item.value.npaContacts = []
  item.value.npaRegistry.forEach(function (f) {
    Regions.npaContacts(f.id)
        .then(({data}) => {
          data.forEach(function (i) {
            item.value.npaContacts.push(i)
          })
        }).catch(() => {
    })
  })
  Shared.closeModal('#add-contact-npa')
}

async function showEditContact(c) {
  contact.value = c
  Shared.showModal('#edit-contact')
}

async function showNPAEditContact(c) {
  contact.value = c
  Shared.showModal('#edit-contact-npa')
}

async function saveContact() {
  await Rgo.updateContact(item.value.rgo.id, contact.value.id, contact.value)
  await Rgo.item(item.value.fund.region.id)
      .then(({data}) => {
        item.value.rgo = data
      }).catch(() => {
      })
  Shared.closeModal('#edit-contact')
}

async function saveNPAContact() {
  await Regions.updateNPAContact(contact.value.regulationId, contact.value.id, {
    "fullName": contact.value.fullName,
    "occupation": contact.value.occupation,
    "phone": contact.value.phone,
    "email": contact.value.email,
    "comment": contact.value.comment
  })
  item.value.npaContacts = []
  item.value.npaRegistry.forEach(function (f) {
    Regions.npaContacts(f.id)
        .then(({data}) => {
          data.forEach(function (i) {
            i.regulationId = f.id
            item.value.npaContacts.push(i)
          })
        }).catch(() => {
    })
  })
  Shared.closeModal('#edit-contact-npa')
}

async function showRemoveContact() {
  Shared.closeModal('#edit-contact')
  Shared.showModal('#del-contact')
}

async function showNPARemoveContact() {
  Shared.closeModal('#edit-contact-npa')
  Shared.showModal('#del-contact-npa')
}

async function removeContact() {
  await Rgo.removeContact(item.value.fund.region.id, contact.value.id)
  await Rgo.item(item.value.fund.region.id)
      .then(({data}) => {
        item.value.rgo = data
      }).catch(() => {
      })
  Shared.closeModal('#del-contact')
  Shared.showModal('#close-contact')
}

async function removeNPAContact() {
  await Regions.removeNPAContact(contact.value.regulationId, contact.value.id)
  item.value.npaContacts = []
  item.value.npaRegistry.forEach(function (f) {
    Regions.npaContacts(f.id)
        .then(({data}) => {
          data.forEach(function (i) {
            i.regulationId = f.id
            item.value.npaContacts.push(i)
          })
        }).catch(() => {
    })
  })
  Shared.closeModal('#del-contact-npa')
  Shared.showModal('#close-contact')
}

async function saveAddСollabPrograms() {
  await Funds.createCollabProgram(route.params.fund, collab.value)
  await Funds.collabPrograms(route.params.fund)
      .then(({data}) => {
        item.value.collabPrograms = data
      }).catch(() => {
      })
  Shared.closeModal('#add-joint-programs')
}

async function showEditСollabPrograms(cl) {
  await Dictionary.collabPrograms()
      .then(({data}) => {
        collabPrograms.value = data
      }).catch(() => {
      })

  collab.value = {
    id: cl.id,
    type: cl.collabProgramTypesType,
    startDate: cl.startDate,
    rfrpSiteUrl: cl.rfrpSiteUrl,
    gispSiteUrl: cl.gispSiteUrl,
  }
  Shared.showModal('#edit-joint-programs')
}

async function saveEditСollabPrograms() {
  await Funds.updateCollabProgram(route.params.fund, collab.value.id, collab.value)
  await Funds.collabPrograms(route.params.fund)
      .then(({data}) => {
        item.value.collabPrograms = data
      }).catch(() => {
      })
  Shared.closeModal('#edit-joint-programs')
}

async function showRemoveCollamb() {
  Shared.closeModal('#edit-joint-programs')
  Shared.showModal('#del-document')
}

async function removeEditСollabPrograms() {
  await Funds.removeCollabProgram(route.params.fund, collab.value.id)
  await Funds.collabPrograms(route.params.fund)
      .then(({data}) => {
        item.value.collabPrograms = data
      }).catch(() => {
      })
  Shared.closeModal('#del-document')
  Shared.showModal('#close-document')
}

async function showRFRPDocument() {
  uploadDocument.value = {
    visibility: 0
  }
  Shared.showModal('#add-document')
}

async function showEditRFRPDocument(doc) {
  docFile.value = {}
  uploadDocument.value = {
    id: doc.id,
    //temporaryFileId: doc.fileId,
    typeName: doc.typeName,
    number: doc.number,
    name: doc.name,
    date: doc.date,
    visibility: doc.isPublic ? 1 : 0
  }
  Shared.showModal('#edit-document')
}

async function uploadRFRPDocument() {
  let formData = new FormData();
  formData.append("data", docFile.value);
  formData.append("name", docFile.value.name);
  formData.append("mimeType", docFile.value.type);
  await FileDocument.fileTemp(formData).then(({data}) => {
    uploadDocument.value.name = data.name
    uploadDocument.value.temporaryFileId = data.id
    Funds.createDocument(route.params.fund, uploadDocument.value)
        .then(async () => {
          await Funds.documents(route.params.fund)
              .then(({data}) => {
                item.value.fundDocuments = data
                Shared.closeModal('#add-document')
              }).catch(() => {
              })
        }).catch(() => {
    })
  }).catch(() => {

  })
}

async function updateRFRPDocument() {
  if (docFile.value && docFile.value.name) {
    let formData = new FormData();
    formData.append("data", docFile.value);
    formData.append("name", docFile.value.name);
    formData.append("mimeType", docFile.value.type);
    await FileDocument.fileTemp(formData).then(({data}) => {
      uploadDocument.value.name = data.name
      uploadDocument.value.temporaryFileId = data.id
    }).catch(() => {

    })
  }
  Funds.updateDocument(route.params.fund, uploadDocument.value.id, uploadDocument.value)
      .then(async () => {
        await Funds.documents(route.params.fund)
            .then(({data}) => {
              item.value.fundDocuments = data
              Shared.closeModal('#edit-document')
            }).catch(() => {
            })
      }).catch(() => {
  })
}

async function showRGODocument() {
  uploadDocument.value = {
    visibility: 0
  }
  Shared.showModal('#add-document-rgo')
}

async function showEditRGODocument(doc) {
  docFile.value = {}
  uploadDocument.value = {
    id: doc.id,
    //temporaryFileId: doc.fileId,
    typeName: doc.typeName,
    number: doc.number,
    name: doc.name,
    date: doc.date,
    visibility: doc.isPublic ? 1 : 0
  }
  Shared.showModal('#edit-document-rgo')
}

async function uploadRGODocument() {
  let formData = new FormData();
  formData.append("data", docFile.value);
  formData.append("name", docFile.value.name);
  formData.append("mimeType", docFile.value.type);
  await FileDocument.fileTemp(formData).then(({data}) => {
    uploadDocument.value.name = data.name
    uploadDocument.value.temporaryFileId = data.id
    Rgo.createDocument(item.value.rgo.id, uploadDocument.value)
        .then(async () => {
          await Rgo.documents(item.value.rgo.id)
              .then(({data}) => {
                item.value.rgoDocuments = data
                Shared.closeModal('#add-document-rgo')
              }).catch(() => {
              })
        }).catch(() => {
    })
  }).catch(() => {

  })
}

async function updateRGODocument() {
  if (docFile.value && docFile.value.name) {
    let formData = new FormData();
    formData.append("data", docFile.value);
    formData.append("name", docFile.value.name);
    formData.append("mimeType", docFile.value.type);
    await FileDocument.fileTemp(formData).then(({data}) => {
      uploadDocument.value.name = data.name
      uploadDocument.value.temporaryFileId = data.id
    }).catch(() => {

    })
  }
  Rgo.updateDocument(item.value.rgo.id, uploadDocument.value.id, uploadDocument.value)
      .then(async () => {
        await Rgo.documents(item.value.rgo.id)
            .then(({data}) => {
              item.value.rgoDocuments = data
              Shared.closeModal('#edit-document-rgo')
            }).catch(() => {
            })
      }).catch(() => {
  })
}

async function showDelRGODocument(doc) {
  uploadDocument.value = doc
  Shared.closeModal('#edit-document-rgo')
  Shared.showModal('#del-document-rgo')
}

async function removeRGODocument() {
  await Rgo.removeDocument(item.value.rgo.id, uploadDocument.value.id)
  await Rgo.documents(item.value.fund.rgo.id)
      .then(({data}) => {
        item.value.rgoDocuments = data
      }).catch(() => {
      })
  Shared.closeModal('#del-document-rgo')
  Shared.showModal('#close-document2')
}

async function showEditRGOStatus() {
  rgoStatus.value = {
    accreditationStatus: item.value.rgo.signingState.accreditation,
    agreementStatus: item.value.rgo.signingState.signingStatus,
    signingDate: item.value.rgo.signingState.signDate,
    form2021Status: item.value.rgo.signingState.form2021Status,
  }

  Shared.showModal('#edit-status')
}

async function saveEditRGOStatus() {
  await Rgo.updateSigningState(item.value.fund.region.id, item.value.rgo.id, rgoStatus.value)
  await Rgo.item(item.value.fund.region.id)
      .then(({data}) => {
        item.value.rgo = data
        item.value.rgo.notCreated = false
      }).catch(() => {
      })
  Shared.closeModal('#edit-status')
}

async function showRegionDocument() {
  uploadDocument.value = {
    visibility: 0
  }
  Shared.showModal('#add-document-region')
}

async function showEditRegionDocument(doc) {
  docFile.value = {}
  uploadDocument.value = {
    id: doc.id,
    //temporaryFileId: doc.fileId,
    typeName: doc.typeName,
    number: doc.number,
    name: doc.name,
    date: doc.date,
    visibility: doc.isPublic ? 1 : 0
  }
  Shared.showModal('#edit-document-region')
}

async function uploadRegionDocument() {
  let formData = new FormData();
  formData.append("data", docFile.value);
  formData.append("name", docFile.value.name);
  formData.append("mimeType", docFile.value.type);
  await FileDocument.fileTemp(formData).then(({data}) => {
    uploadDocument.value.name = data.name
    uploadDocument.value.temporaryFileId = data.id
    Regions.createDocument(item.value.fund.region.id, uploadDocument.value)
        .then(async () => {
          await Regions.documents(item.value.fund.region.id)
              .then(({data}) => {
                item.value.regionDocuments = data
                Shared.closeModal('#add-document-region')
              }).catch(() => {
              })
        }).catch(() => {
    })
  }).catch(() => {

  })
}

async function updateRegionDocument() {
  if (docFile.value && docFile.value.name) {
    let formData = new FormData();
    formData.append("data", docFile.value);
    formData.append("name", docFile.value.name);
    formData.append("mimeType", docFile.value.type);
    await FileDocument.fileTemp(formData).then(({data}) => {
      uploadDocument.value.name = data.name
      uploadDocument.value.temporaryFileId = data.id
    }).catch(() => {

    })
  }
  Regions.updateDocument(item.value.fund.region.id, uploadDocument.value.id, uploadDocument.value)
      .then(async () => {
        await Regions.documents(item.value.fund.region.id)
            .then(({data}) => {
              item.value.regionDocuments = data
              Shared.closeModal('#edit-document-region')
            }).catch(() => {
            })
      }).catch(() => {
  })
}

async function downloadDoc(id) {
  await FileDocument.fileData(id)
      .then((response) => {
        console.log(response)
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        let blob = new Blob([response.data], {type: "octet/stream"});
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "12.pdf";
        a.click();
      }).catch(() => {
      })
}

async function showDelDocument(doc) {
  uploadDocument.value = doc
  Shared.closeModal('#edit-document')
  Shared.showModal('#del-document2')
}

async function showDelRegionDocument(doc) {
  uploadDocument.value = doc
  Shared.closeModal('#edit-document-region')
  Shared.showModal('#del-document-region')
}

async function removeDocument() {
  await Funds.removeDocument(route.params.fund, uploadDocument.value.id)
  await Funds.documents(route.params.fund)
      .then(({data}) => {
        item.value.fundDocuments = data
      }).catch(() => {
      })
  Shared.closeModal('#del-document2')
  Shared.showModal('#close-document2')
}

async function removeRegionDocument() {
  await Regions.removeDocument(item.value.fund.region.id, uploadDocument.value.id)
  await Regions.documents(item.value.fund.region.id)
      .then(({data}) => {
        item.value.regionDocuments = data
      }).catch(() => {
      })
  Shared.closeModal('#del-document-region')
  Shared.showModal('#close-document2')
}

const goToRegulations = () => {
  const region = item.value.fund.region.id;
  router.push({name: 'ListNpa', params: {region}});
}
const goToCapitalization = () => {
  const fund = item.value.fund.id;
  router.push({name: 'CapitalizationTable', params: {fund}});
}


</script>

<template>
  <div class="wrapper">
    <MainHeader></MainHeader>
    <main class="page page--js">
      <div class="breadcrumbs-container">
        <div class="container">
          <div class="breadcrumbs-wrapper">
            <ul class="breadcrumbs">
              <li>
                <router-link :to="{ name: 'ListRegions' }">Модуль регионов
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'ListRegions' }">Список регионов
                </router-link>
              </li>
              <li>{{ item.fund.region.title }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="content__wrapper">
            <div class="card-row">
              <div class="card-item">
                <div class="card-item__title">
                  <h2 class="title-h2">Статус рассмотрения заявок в ФРП <br>
                    <span>в том числе по совместным программам</span>
                  </h2>
                </div>
                <div class="table-container">
                  <table class="table table--table-mini">
                    <thead>
                    <tr>
                      <th>Текущий статус</th>
                      <th>Кол-во, шт.</th>
                      <th>Сумма займа, млн руб.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Профинансировано</td>
                      <td>{{ item.statistics.claimsVerificationStatus.completed.quantity }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.claimsVerificationStatus.completed.sum, 2) }}</td>
                    </tr>
                    <tr>
                      <td>В работе ФРП</td>
                      <td>{{ item.statistics.claimsVerificationStatus.inProgress.quantity }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.claimsVerificationStatus.inProgress.sum, 2) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-item">
                <div class="card-item__title">
                  <h2 class="title-h2">Ключевые показатели действующих и реализованных проектов<br>
                    <span>за 2015 - 2024 г.</span>
                  </h2>
                </div>
                <div class="table-container">
                  <table class="table table--table-mini">
                    <thead>
                    <tr>
                      <th>Выручка, млрд руб.</th>
                      <th>Налоги, млрд руб.</th>
                      <th>Инвестиции, млрд руб.</th>
                      <th>ВПРМ, шт.</th>
                      <th>РИДы, шт.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>{{ $filters.numberFormat(item.statistics.keyIndicatorsStatus.revenue, 2) }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.keyIndicatorsStatus.taxes, 2) }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.keyIndicatorsStatus.investments, 2) }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.keyIndicatorsStatus.vprm, 2) }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.keyIndicatorsStatus.rids, 2) }}</td>
                    </tr>
                    <tr>
                      <td class="td-text-bold">Всего проектов: <span>20</span></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-item">
                <div class="card-item__title">
                  <h2 class="title-h2">Совместные программы РФРП (статистика)
                  </h2>
                </div>
                <div class="table-container">
                  <table class="table table--table-mini">
                    <thead>
                    <tr>
                      <th>Текущий статус</th>
                      <th>Кол-во, шт.</th>
                      <th>Сумма займа ФРП, млн руб.</th>
                      <th>Сумма займа РФРП, млн руб.</th>
                      <th>Сумма займа итого, млн руб.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Профинансировано</td>
                      <td>{{ $filters.numberFormat(item.statistics.collaborationStatus.completed.quantity, 0) }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.collaborationStatus.completed.frpLoanSum, 2) }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.collaborationStatus.completed.rfrpLoanSum, 2) }}</td>
                      <td>{{
                          $filters.numberFormat(item.statistics.collaborationStatus.completed.loanSumTotal, 2)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>В работе ФРП</td>
                      <td>{{
                          $filters.numberFormat(item.statistics.collaborationStatus.inProgressFrp.quantity, 0)
                        }}
                      </td>
                      <td>{{
                          $filters.numberFormat(item.statistics.collaborationStatus.inProgressFrp.frpLoanSum, 2)
                        }}
                      </td>
                      <td>{{
                          $filters.numberFormat(item.statistics.collaborationStatus.inProgressFrp.rfrpLoanSum, 2)
                        }}
                      </td>
                      <td>{{
                          $filters.numberFormat(item.statistics.collaborationStatus.inProgressFrp.loanSumTotal, 2)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>В работе РФРП</td>
                      <td>{{ $filters.numberFormat(item.statistics.collaborationStatus.inProgress.quantity, 0) }}</td>
                      <td>{{ $filters.numberFormat(item.statistics.collaborationStatus.inProgress.frpLoanSum, 2) }}</td>
                      <td>{{
                          $filters.numberFormat(item.statistics.collaborationStatus.inProgress.rfrpLoanSum, 2)
                        }}
                      </td>
                      <td>{{
                          $filters.numberFormat(item.statistics.collaborationStatus.inProgress.loanSumTotal, 2)
                        }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-item">
                <div class="card-item__title">
                  <h2 class="title-h2">самостоятельные программы РФРП (статистика)
                  </h2>
                </div>
                <div class="table-container">
                  <table class="table table--table-mini">
                    <thead>
                    <tr>
                      <th>Текущий статус</th>
                      <th>Кол-во, шт.</th>
                      <th>Сумма займа, млн руб.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Профинансировано всего</td>
                      <td>{{
                          $filters.numberFormat(item.statistics.ownProgramsStatus.completedTotally.quantity, 0)
                        }}
                      </td>
                      <td>{{ $filters.numberFormat(item.statistics.ownProgramsStatus.completedTotally.sum, 2) }}</td>
                    </tr>
                    <tr>
                      <td>в т.ч. профинансировано за {{ new Date().getFullYear() }} г.</td>
                      <td>{{
                          $filters.numberFormat(item.statistics.ownProgramsStatus.completedTotally.quantity, 0)
                        }}
                      </td>
                      <td>{{ $filters.numberFormat(item.statistics.ownProgramsStatus.completedTotally.sum, 2) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="content__title content__title--margin">
              <h1 class="title">РФРП</h1>
            </div>
            <div class="card-row">
              <div class="card-item card-item--entry-info">
                <div class="card-item__title">
                  <h3 class="title-h3 align-items">Основная информация
                    <button type="button" class="button-edit" @click="showMainRFRP"
                            v-if="permission('/funds:update')"></button>
                  </h3>
                </div>
                <div class="entry-info entry-info--region-card grid-bg">
                  <div class="entry-info__item">Официальное название<a href="#" class="link width-60percent">
                    {{ item.fund.legalTitle }}
                  </a></div>
                  <div class="entry-info__item">Субъект РФ<span>
                      {{ item.fund.region.title }}</span>
                  </div>
                  <div class="entry-info__item">Федеральный округ<span>{{ item.fund.region.district.title }}</span>
                  </div>
                  <div class="entry-info__item">Сайт<span class="color-active">{{ item.fund.siteUrl }}</span></div>
                  <div class="entry-info__item">Общий e-mail<span class="color-active">{{
                      item.fund.generalEmail
                    }}</span>
                  </div>
                  <div class="entry-info__item">Общий телефон<span class="color-active">{{
                      item.fund.generalPhone
                    }}</span></div>
                  <div class="entry-info__item" :class="{ hidden: hideRfrpInfo }">ОГРН<span>{{ item.fund.ogrn }}</span>
                  </div>
                  <div class="entry-info__item" :class="{ hidden: hideRfrpInfo }">Организационно-правовая форма<span
                      class="color-active">{{ item.fund.legalFormTitle }}</span></div>
                  <div class="entry-info__item" :class="{ hidden: hideRfrpInfo }">Юридический адрес<span
                      class="width-60percent">{{ item.fund.legalAddress }}</span></div>
                  <div class="entry-info__item" :class="{ hidden: hideRfrpInfo }">Фактический адрес<span
                      class="width-60percent color-active">{{ item.fund.actualAddress }}</span></div>
                  <div class="entry-info__item" :class="{ hidden: hideRfrpInfo }">Учредитель РФРП<span
                      class="color-active">{{ item.fund.frpFounder }}</span></div>
                  <div class="entry-info__item" :class="{ hidden: hideRfrpInfo }">Реквизиты НПА о создании РФРП<span
                      class="color-active">{{ item.fund.npaRequisites }}</span></div>
                  <div class="entry-info__item" :class="{ hidden: hideRfrpInfo }">Дата подписания соглашения<span
                      class="color-active">{{ moment(item.fund.agreementSigningDate).format("DD.MM.YYYY") }}</span>
                  </div>
                  <div class="entry-info__item">
                    <button type="button" class="button-hide align-items">
                      <span class="button-hide__text " :class="{
                        'button-hide__text--default': hideRfrpInfo,
                        'button-hide__text--active': !hideRfrpInfo
                      }" @click="hideRfrpInfo = false">Показать</span>
                      <span class="button-hide__text " :class="{
                        'button-hide__text--default': !hideRfrpInfo,
                        'button-hide__text--active': hideRfrpInfo
                      }" @click="hideRfrpInfo = true">Скрыть</span>
                      <span class="button-hide__icon"></span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-item-wrapper">
                <div class="card-item card-item--entry-info">
                  <div class="card-item__title">
                    <h3 class="title-h3 align-items">Ответственные лица ФРП
                      <button type="button" class="button-edit" @click="showResponsibilities"
                              data-modal="responsible-person"></button>
                    </h3>
                  </div>
                  <div class="entry-info entry-info--region-card">
                    <div class="entry-info__item">Менеджер от департамента проектов<span class="color-active">
                        {{ item.responsibilities.projectManager.shortName }}
                        {{ item.responsibilities.projectManager.email }}</span>
                    </div>
                    <div class="entry-info__item">Эксперт по региону<span class="color-active">
                        {{ item.responsibilities.regionalExpert.shortName }}
                        {{ item.responsibilities.regionalExpert.email }}
                      </span>
                    </div>

                  </div>
                </div>
                <div class="card-item card-item--entry-info">
                  <div class="card-item__title card-item__title--clickable"
                       @click="hideRfrpContacts = !hideRfrpContacts">
                    <h3 class="title-h3">Контактные лица РФРП</h3>
                    <span class="card-item__title-icon"></span>
                  </div>
                  <div class="table-container">
                    <table class="table table--table-mini table--table-mini-white"
                           :class="{ hidden: hideRfrpContacts }">
                      <thead>
                      <tr>
                        <th>ФИО</th>
                        <th>Должность</th>
                        <th>Телефон</th>
                        <th>E-mail</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="i in item.fundContacts" :key="i.id">
                        <td>{{ i.shortName }}</td>
                        <td></td>
                        <td>{{ i.phone }}</td>
                        <td>{{ i.email }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="buttons-block buttons-block--right" :class="{ hidden: hideRfrpContacts }">
                    <a :href="'mailto:' + item.fundContacts.map(u => u.email).join(',')" class="button">отправить
                      письмо</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-row">
              <div class="card-item-wrapper">
                <div class="card-item card-item--bg-white">
                  <div class="card-item__title">
                    <h2 class="title-h2">Бюджет и капитализация РФРП</h2>
                  </div>
                  <div class="card-item__body">
                    <div class="card-item__body-item">Бюджет на 2024 г., млн руб.<span>

                        {{ $filters.numberFormat(item.capitalizationStat.budgetAmount, 2) }}</span>
                    </div>
                    <div class="card-item__body-item">Капитализация, млн руб.<span>
                        {{ $filters.numberFormat(item.capitalizationStat.capitalizationAmount, 2) }}</span>
                    </div>
                  </div>
                  <div class="buttons-block buttons-block--right">
                    <button type="button" class="button" @click="goToCapitalization">капитализация рфрп</button>
                  </div>
                </div>
                <div class="card-item">
                  <div class="card-item__title">
                    <h3 class="title-h2 align-items">Совместные программы РФРП
                      <button v-if="permission('/funds/collab-programs:update')" type="button" class="button-add"
                              @click="showAddCollabPrograms"></button>
                    </h3>
                  </div>
                  <div class="table-container">
                    <table class="table table--table-mini table--text-align-right">
                      <thead>
                      <tr>
                        <th>Название программы</th>
                        <th>Ссылка на навигатор ГИСП</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="i in item.collabPrograms" :key="i.id">
                        <td>
                          <a v-if="i.rfrpSiteUrl" :href="i.rfrpSiteUrl" class="link link--color-main">{{ i.title }}</a>
                          <span v-else>{{ i.title }}</span>
                        </td>
                        <td>
                          <div class="table-button-block"><a v-if="i.gispSiteUrl" :href="i.gispSiteUrl"
                                                             class="button">Перейти</a>
                            <button
                                v-if="permission('/funds/collab-programs:update')"
                                type="button" class="button-edit" @click="showEditСollabPrograms(i)"></button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-item">
                  <div class="card-item__title">
                    <h3 class="title-h2 align-items">Документы РФРП
                      <button v-if="permission('/funds/documents:update')" type="button" class="button-add"
                              @click="showRFRPDocument"></button>
                    </h3>
                  </div>
                  <div class="table-container">
                    <table class="table table--table-mini">
                      <thead>
                      <tr>
                        <th>Тип документа</th>
                        <th>Номер документа</th>
                        <th>Дата документа</th>
                        <th>Публичный</th>
                        <th>Документ</th>
                        <th v-if="permission('/funds/documents:update')"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="i in item.fundDocuments" :key="i.id">
                        <td>{{ i.typeName }}</td>
                        <td>{{ i.number }}</td>
                        <td>{{ i.date }}</td>
                        <td>{{ i.isPublic ? 'Да' : 'Нет' }}</td>
                        <td>
                          <button type="button" class="button-doc" @click="downloadDoc(i.fileId)"></button>
                        </td>
                        <td v-if="permission('/funds/documents:update')">
                          <div class="table-button-block">
                            <button type="button" class="button-edit" @click="showEditRFRPDocument(i)"></button>
                            <button type="button" class="button-del" @click="showDelDocument(i)"></button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-item-wrapper">
                <div class="card-item">
                  <div class="card-item__title">
                    <h3 class="title-h2 align-items">самостоятельные программы РФРП
                      <router-link
                          v-if="permission('/funds/own-programs:update')"
                          :to="{ name: 'AddOwnProgram', params: { id: item.fund.region.id, fund: route.params.fund } }"
                          class="button-add">
                      </router-link>
                    </h3>
                  </div>
                  <div class="table-container">
                    <table class="table table--table-mini">
                      <thead>
                      <tr>
                        <th>Название программы</th>
                        <th>Сумма, млн руб</th>
                        <th>Срок, лет</th>
                        <th>Ставка, %</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="i in item.ownPrograms" :key="i.id">
                        <td>
                          <router-link
                              :to="{ name: 'EditOwnProgram', params: { id: item.fund.region.id, fund: route.params.fund, own: i.id } }"
                              class="link">{{ i.title }}
                          </router-link>
                        </td>
                        <td>{{ i.loanSumMin }} - {{ i.loanSumMax }}</td>
                        <td>{{ i.loanDuration }}</td>
                        <td>{{ i.rate }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-item card-item--bg-white">
                  <div class="card-item__title">
                    <h2 class="title-h2">договоры займа по самостоятельным программам</h2>
                  </div>
                  <div class="card-item__body">
                    <div class="card-item__body-item">Всего договоров<span>{{
                        item.agreementStatistics.totalCount
                      }}</span>
                    </div>
                    <div class="card-item__body-item">Сумма займов,
                      руб.<span>{{ $filters.numberFormat(item.agreementStatistics.totalLoanSum, 2) }}</span>
                    </div>
                  </div>
                  <div class="card-item__body">
                    <div class="card-item__body-item">Всего договоров в {{ new Date().getFullYear() }}
                      г.<span>{{ item.agreementStatistics.totalCountOfYear }}</span>
                    </div>
                    <div class="card-item__body-item">Сумма займов в {{ new Date().getFullYear() }} г.,
                      руб.<span>{{ $filters.numberFormat(item.agreementStatistics.totalLoanSumOfYear, 2) }}</span>
                    </div>
                  </div>
                  <div class="buttons-block buttons-block--right">
                    <router-link :to="{ name: 'ListAgreementsByRegion', params: { id: item.fund.id } }" class="button">
                      реестр договоров
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="content__title content__title--margin" v-if="permission('/rgo:read')">
              <h1 class="title">РГО</h1>
            </div>
            <div class="card-row" v-if="permission('/rgo:read')">
              <div class="card-item-wrapper">
                <div class="card-item card-item--entry-info">
                  <div class="card-item__title">
                    <h3 class="title-h3 align-items">Основная информация
                      <button type="button" class="button-edit" v-if="!item.rgo.notCreated && permission('/rgo:update')"
                              @click="showMainRGO"></button>
                      <button type="button" class="button-edit" v-else-if="permission('/rgo:update')"
                              @click="showCreateMainRGO"></button>
                    </h3>
                  </div>
                  <div class="entry-info entry-info--region-card grid-bg" v-if="!item.rgo.notCreated">
                    <div class="entry-info__item">Название РГО<a href="#"
                                                                 class="link width-60percent">
                      {{ item.rgo.id }}
                    </a></div>
                    <div class="entry-info__item"
                         v-if="item.rgo.guaranteeAmounts.find(s => s.year === (new Date()).getFullYear())">Размер
                      гарантийного капитала на 2024 г., млн
                      руб.<span
                          class="color-active">{{ item.rgo.guaranteeAmounts.find(s => s.year === (new Date()).getFullYear()).amount }}</span>
                    </div>
                    <div class="entry-info__item">Лимит на 1 заем, млн руб.<span class="color-active">{{
                        item.rgo.loanLimit
                      }}</span>
                    </div>
                    <div class="entry-info__item">Займы ФРП, обеспеченные РГО<span class="color-active">—</span></div>
                    <div class="entry-info__item" :class="{ hidden: hideRgoInfo }">Общий e-mail<span
                        class="color-active">info@frp61.ru</span></div>
                    <div class="entry-info__item" :class="{ hidden: hideRgoInfo }">Общий телефон<span
                        class="color-active">{{ item.rgo.generalPhone }}</span></div>
                    <div class="entry-info__item" :class="{ hidden: hideRgoInfo }">ОГРН<span>{{ item.rgo.ogrn }}</span>
                    </div>
                    <div class="entry-info__item" :class="{ hidden: hideRgoInfo }">Адрес<span class="color-active">{{
                        item.rgo.address
                      }}</span></div>
                    <div class="entry-info__item">
                      <button type="button" class="button-hide align-items">
                        <span class="button-hide__text " :class="{
                          'button-hide__text--default': hideRgoInfo,
                          'button-hide__text--active': !hideRgoInfo
                        }" @click="hideRgoInfo = false">Показать</span>
                        <span class="button-hide__text " :class="{
                          'button-hide__text--default': !hideRgoInfo,
                          'button-hide__text--active': hideRgoInfo
                        }" @click="hideRgoInfo = true">Скрыть</span>
                        <span class="button-hide__icon"></span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-item" v-if="!item.rgo.notCreated">
                  <div class="card-item__title card-item__title--clickable">
                    <h2 class=" title-h2 align-items">Документы РФРП
                      <button type="button" class="button-add" v-if="permission('/rgo/documents:update')"
                              @click="showRGODocument"></button>
                    </h2>
                    <span class="card-item__title-icon" @click="hideRgoDocs = !hideRgoDocs"></span>
                  </div>
                  <div class="table-container" :class="{hidden: hideRgoDocs}">
                    <table class="table table--table-mini">
                      <thead>
                      <tr>
                        <th>Тип документа</th>
                        <th>Номер документа</th>
                        <th>Номер документа</th>
                        <th>Публичный</th>
                        <th>Документ</th>
                        <th v-if="permission('/rgo/documents:update')"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="i in item.rgoDocuments" :key="i.id">
                        <td>{{ i.typeName }}</td>
                        <td>{{ i.number }}</td>
                        <td>{{ i.date }}</td>
                        <td>{{ i.isPublic ? 'Да' : 'Нет' }}</td>
                        <td>
                          <button type="button" class="button-doc" @click="downloadDoc(i.fileId)"></button>
                        </td>
                        <td v-if="permission('/rgo/documents:update')">
                          <div class="table-button-block">
                            <button type="button"
                                    class="button-edit" @click="showEditRGODocument(i)"></button>
                            <button type="button"
                                    class="button-del" @click="showDelRGODocument(i)"></button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-item-wrapper" v-if="!item.rgo.notCreated">
                <div class="card-item card-item--entry-info">
                  <div class="card-item__title">
                    <h3 class="title-h3 align-items">Статус подписания соглашения
                      <button type="button" class="button-edit" @click="showEditRGOStatus"
                              v-if="permission('/rgo:update')"></button>
                    </h3>
                  </div>
                  <div class="entry-info entry-info--region-card">
                    <div class="entry-info__item">Аккредитация в РГО
                      <span class="color-active"
                            v-if="item.rgo.signingState.accreditation">
                        {{ rgoAccreditations.find(v => v.value === item.rgo.signingState.accreditation).title }}
                      </span>
                    </div>
                    <div class="entry-info__item">Статус соглашения
                      <span class="color-active"
                            v-if="item.rgo.signingState.signingStatus">
                        {{ rgoAgreementStatuses.find(v => v.value === item.rgo.signingState.signingStatus).title }}
                      </span>
                    </div>
                    <div class="entry-info__item">Дата подписания<span class="color-active"
                                                                       v-if="!item.rgo.signingState.signingStatus">—</span>
                      <span class="color-active" v-if="item.rgo.signingState.signingStatus">{{
                          item.rgo.signingState.signDate
                        }}</span>
                    </div>
                    <div class="entry-info__item">Заключение/перезаключение соглашения по типовой
                      форме 2021
                      <span class="color-active"
                            v-if="item.rgo.signingState.form2021Status">
                        {{ rgoForm2021Statuses.find(v => v.value === item.rgo.signingState.form2021Status).title }}
                      </span>
                    </div>
                    <div class="entry-info__item">Комментарий
                      <div class="table-button-block"><span class="color-active">Отказ от подписания типовой формы
                          2021</span>
                        <button type="button" class="button-edit"></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-item card-item--entry-info">
                  <div class="card-item__title card-item__title--clickable" @click="hideRgoContacts = !hideRgoContacts">
                    <h2 class=" title-h2 align-items">Контактные лица
                      <button type="button" class="button-add" @click="showAddContact"
                              v-if="permission('/rgo/contacts:update')"></button>
                    </h2>
                    <span class="card-item__title-icon"></span>
                  </div>
                  <div class="table-container">
                    <table class="table table--table-mini table--table-mini-white" :class="{ hidden: hideRgoContacts }">
                      <thead>
                      <tr>
                        <th>ФИО</th>
                        <th>Должность</th>
                        <th>Телефон</th>
                        <th>E-mail</th>
                        <th>Комментарий</th>
                        <th v-if="permission('/rgo/contacts:update')"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="i in item.rgo.contacts" :key="i.id">
                        <td>{{ i.fullName }}</td>
                        <td>{{ i.occupation }}</td>
                        <td>{{ i.phone }}</td>
                        <td>{{ i.email }}</td>
                        <td>{{ i.comment }}</td>
                        <td v-if="permission('/rgo/contacts:update')">
                          <a @click="showEditContact(i)" type="button" class="button-edit"></a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="content__title content__title--margin"  v-if="permission('/regions:read')">
              <h1 class="title">Документы</h1>
            </div>
            <div class="card-row" v-if="permission('/regions:read')">
              <div class="card-item-wrapper">
                <div class="card-item">
                  <div class="card-item__title card-item__title--clickable">
                    <h2 class=" title-h2 align-items">Документы РЕГИОНА
                      <button type="button" class="button-add" @click="showRegionDocument"
                              v-if="permission('/regions/documents:update')"></button>
                    </h2>
                    <span class="card-item__title-icon"></span>
                  </div>
                  <div class="table-container">
                    <table class="table table--table-mini">
                      <thead>
                      <tr>
                        <th>Тип документа</th>
                        <th>Номер документа</th>
                        <th>Номер документа</th>
                        <th>Публичный</th>
                        <th>Документ</th>
                        <th v-if="permission('/regions/documents:update')"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="i in item.regionDocuments" :key="i.id">
                        <td>{{ i.typeName }}</td>
                        <td>{{ i.number }}</td>
                        <td>{{ i.date }}</td>
                        <td>{{ i.isPublic ? 'Да' : 'Нет' }}</td>
                        <td>
                          <button type="button" class="button-doc" @click="downloadDoc(i.fileId)"></button>
                        </td>
                        <td v-if="permission('/regions/documents:update')">
                          <div class="table-button-block">
                            <button type="button" class="button-edit" @click="showEditRegionDocument(i)"></button>
                            <button type="button" class="button-del" @click="showDelRegionDocument(i)"></button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="content__title content__title--margin" v-if="permission('/npa/contacts:read')">
              <h1 class="title">СПИК</h1>
            </div>
            <div class="card-row" v-if="permission('/npa/contacts:read')">
              <div class="card-item card-item--bg-white">
                <div class="card-item__title">
                  <h2 class="title-h2">НПА по спик</h2>
                </div>
                <div class="card-item__body">
                  <div class="card-item__body-item card-item__body-item-m-width100">Всего
                    документов<span>{{ item.npaStatistics.totalRegulations }}</span>
                  </div>
                </div>
                <div class="buttons-block buttons-block--right">
                  <button type="button" class="button" @click="goToRegulations">реестр нпа по спик</button>
                </div>
              </div>
              <div class="card-item card-item--entry-info">
                <div class="card-item__title card-item__title--clickable">
                  <h2 class=" title-h2 align-items">Контактные лица
                    <button v-if="permission('/npa/contacts:update')" type="button" class="button-add" @click="showNPAAddContact"></button>
                  </h2>
                  <span class="card-item__title-icon"></span>
                </div>
                <div class="table-container">
                  <table class="table table--table-mini table--table-mini-white">
                    <thead>
                    <tr>
                      <th>ФИО</th>
                      <th>Должность</th>
                      <th>Телефон</th>
                      <th>E-mail</th>
                      <th>Комментарий</th>
                      <th  v-if="permission('/npa/contacts:update')"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="i in item.npaContacts" :key="i.id">
                      <td>{{ i.fullName }}</td>
                      <td>{{ i.occupation }}</td>
                      <td>{{ i.phone }}</td>
                      <td>{{ i.email }}</td>
                      <td>{{ i.comment }}</td>
                      <td v-if="permission('/npa/contacts:update')">
                        <a @click="showNPAEditContact(i)" type="button" class="button-edit"></a>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="responsible-person">
        <div class="popup">
          <div class="modal-title title-h3">Ответственные лица ФРП</div>
          <div class="modal-body">
            <label for="manager" class="title-field">Менеджер от департамента проектов
              <select v-model="responsibilities.projectManagerId" class="field field--select">
                <option v-for="i in projectManagers" :value="i.id" :key="i.id">{{ i.shortName }}</option>
              </select>
            </label>
            <label for="expert" class="title-field">Эксперт по региону
              <select v-model="responsibilities.regionalExpertId" class="field field--select">
                <option v-for="i in regionalExperts" :value="i.id" :key="i.id">{{ i.shortName }}</option>
              </select>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear" @click="Shared.closeModal('#responsible-person')">
                отменить
              </button>
              <button type="submit" class="button" @click="saveResponsibilities">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="edit-joint-programs">
        <div class="popup">
          <div class="modal-title title-h3">Редактирование совместной программы</div>
          <div class="modal-body">
            <label for="program1-name" class="title-field">Название программы*
              <select v-model="collab.type" class="field field--select">
                <option :value="i.value" v-for="i in collabPrograms" :key="i.value">{{ i.title }}</option>
              </select>
            </label>
            <label for="program-start-date" class="title-field">Дата запуска проаграммы*
              <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                             v-model="collab.startDate"/>
            </label>
            <label for="gisp-card-link1" class="title-field">Ссылка на сайт РФРП
              <input class="field" type="text" v-model="collab.rfrpSiteUrl"
                     placeholder="Введите ссылку на программу на сайте РФРП">
            </label>
            <label for="rfrp-card-link1" class="title-field">Ссылка на навигатор ГИСП
              <input class="field" type="text" v-model="collab.gispSiteUrl"
                     placeholder="Введите ссылку на программу в навигаторе мер поддержки">
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="submit" class="button button--delete" @click="showRemoveCollamb">Удалить</button>
              <button type="reset" class="button button--clear" @click="Shared.closeModal('#edit-joint-programs')">
                отменить
              </button>
              <button type="submit" class="button" @click="saveEditСollabPrograms">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="add-joint-programs">
        <div class="popup">
          <div class="modal-title title-h3">Добавление совместной программы</div>
          <div class="modal-body">
            <label for="program1-name" class="title-field">Название программы*
              <select v-model="collab.type" class="field field--select">
                <option :value="i.value" v-for="i in collabPrograms" :key="i.value">{{ i.title }}</option>
              </select>
            </label>
            <label for="program-start-date" class="title-field">Дата запуска проаграммы*
              <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                             v-model="collab.startDate"/>
            </label>
            <label for="gisp-card-link1" class="title-field">Ссылка на сайт РФРП
              <input class="field" type="text" v-model="collab.rfrpSiteUrl"
                     placeholder="Введите ссылку на программу на сайте РФРП">
            </label>
            <label for="rfrp-card-link1" class="title-field">Ссылка на навигатор ГИСП
              <input class="field" type="text" v-model="collab.gispSiteUrl"
                     placeholder="Введите ссылку на программу в навигаторе мер поддержки">
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear" @click="Shared.closeModal('#add-joint-programs')">
                отменить
              </button>
              <button type="submit" class="button" @click="saveAddСollabPrograms">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="edit-status">
        <div class="popup">
          <div class="modal-title title-h3">Редактирование статуса подписания соглашения</div>
          <div class="modal-body">
            <label for="accreditation-rgo" class="title-field">Аккредитация в РГО
              <select name="accreditation-rgo" v-model="rgoStatus.accreditationStatus" id=""
                      class="field field--select">
                <option :value="i.value" :key="i" v-for="i in rgoAccreditations">{{ i.title }}</option>
              </select>
            </label>
            <div class="interval-fields">
              <label for="status-rgo" class="title-field">Статус соглашения
                <select name="status-rgo" id="" v-model="rgoStatus.agreementStatus" class="field field--select">
                  <option :value="i.value" :key="i" v-for="i in rgoAgreementStatuses">{{ i.title }}</option>
                </select>
              </label>
              <label for="date-rgo" class="title-field">Дата подписания
                <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                               v-model="rgoStatus.signingDate"/>
              </label>
            </div>
            <label for="select-rgo" class="title-field">Заключение/перезаключение соглашения по типовой
              форме 2021
              <select name="select-rgo" id="" v-model="rgoStatus.form2021Status" class="field field--select">
                <option :value="i.value" :key="i" v-for="i in rgoForm2021Statuses">{{ i.title }}</option>
              </select>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear" @click="Shared.closeModal('#edit-status')">отменить
              </button>
              <button type="submit" class="button" @click="saveEditRGOStatus">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="comment-status">
        <div class="popup">
          <div class="modal-title title-h3">Комментарий статуса подписания соглашения</div>
          <div class="modal-body">
            <div class="comment-item">
              <div class="comment-item__date title-h3">02.12.2023</div>
              <div class="comment-item__description">
                <div class="comment-item__title">Отказ от подписания типовой формы 2021</div>
                <div class="comment-item__text">Сотрудник ФРП Тестовый</div>
              </div>
            </div>
            <div class="comment-item">
              <div class="comment-item__date title-h3">01.12.2023</div>
              <div class="comment-item__description">
                <div class="comment-item__title">Соглашение не хотят</div>
                <div class="comment-item__text">Сотрудник ФРП Тестовый</div>
              </div>
            </div>
            <label for="comment1" class="title-field">Комментарий
              <textarea class="field field--textarea" type="text" name="comment1" id=""
                        placeholder="Введите комментарий"></textarea>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear">Закрыть</button>
              <button type="submit" class="button">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="comment-status2">
        <div class="popup">
          <div class="modal-title title-h3">Комментарий статуса подписания соглашения</div>
          <div class="modal-body">
            <div class="comment-item">
              <div class="comment-item__date title-h3">02.12.2023</div>
              <div class="comment-item__description">
                <div class="comment-item__title">Отказ от подписания типовой формы 2021</div>
                <div class="comment-item__text">Сотрудник ФРП Тестовый</div>
              </div>
            </div>
            <div class="comment-item">
              <div class="comment-item__date title-h3">01.12.2023</div>
              <div class="comment-item__description">
                <div class="comment-item__title">Соглашение не хотят</div>
                <div class="comment-item__text">Сотрудник ФРП Тестовый</div>
              </div>
            </div>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear">Закрыть</button>
              <button type="submit" class="button">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="add-document">
        <div class="popup">
          <div class="modal-title title-h3">Добавление нового документа</div>
          <div class="modal-body">
            <label for="doc-type" class="title-field">Тип документа
              <input class="field" type="text" v-model="uploadDocument.typeName" placeholder="Введите тип документа">
            </label>
            <label for="doc-number" class="title-field">Номер документа
              <input class="field" type="text" v-model="uploadDocument.number" placeholder="Введите номер документа">
            </label>
            <label for="date-doc" class="title-field">Дата
              <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                             v-model="uploadDocument.date"/>
            </label>
            <label for="public-doc" class="title-field">Публичный
              <select v-model="uploadDocument.visibility" class="field field--select">
                <option :value="1">Да</option>
                <option :value="0">Нет</option>
              </select>
            </label>

            <label for="file-doc" class="title-field title-field--relative">Файл документа
              <div class="label-add-file transparent" v-bind="getRootProps()">
                <input v-bind="getInputProps()" :multiple="false" @change="onAddDocFile">
                <span class="field field--file" v-if="!docFile || !docFile.name">Выберите или перетащите сюда файл
                  (максимум 25Мб)</span>
                <span class="field field--file" v-else>{{ docFile.name }}</span>
              </div>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear" @click="Shared.closeModal('#add-document')">отменить
              </button>
              <button type="submit" class="button" @click="uploadRFRPDocument">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="add-document-region">
        <div class="popup">
          <div class="modal-title title-h3">Добавление нового документа</div>
          <div class="modal-body">
            <label for="doc-type" class="title-field">Тип документа
              <input class="field" type="text" v-model="uploadDocument.typeName" placeholder="Введите тип документа">
            </label>
            <label for="doc-number" class="title-field">Номер документа
              <input class="field" type="text" v-model="uploadDocument.number" placeholder="Введите номер документа">
            </label>
            <label for="date-doc" class="title-field">Дата
              <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                             v-model="uploadDocument.date"/>
            </label>
            <label for="public-doc" class="title-field">Публичный
              <select v-model="uploadDocument.visibility" class="field field--select">
                <option :value="1">Да</option>
                <option :value="0">Нет</option>
              </select>
            </label>

            <label for="file-doc" class="title-field title-field--relative">Файл документа
              <div class="label-add-file transparent" v-bind="getRootProps()">
                <input v-bind="getInputProps()" :multiple="false" @change="onAddDocFile">
                <span class="field field--file" v-if="!docFile || !docFile.name">Выберите или перетащите сюда файл
                  (максимум 25Мб)</span>
                <span class="field field--file" v-else>{{ docFile.name }}</span>
              </div>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear"
                      @click="Shared.closeModal('#add-document-region')">отменить
              </button>
              <button type="submit" class="button" @click="uploadRegionDocument">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="add-document-rgo">
        <div class="popup">
          <div class="modal-title title-h3">Добавление нового документа</div>
          <div class="modal-body">
            <label for="doc-type" class="title-field">Тип документа
              <input class="field" type="text" v-model="uploadDocument.typeName" placeholder="Введите тип документа">
            </label>
            <label for="doc-number" class="title-field">Номер документа
              <input class="field" type="text" v-model="uploadDocument.number"
                     placeholder="Введите номер документа">
            </label>
            <label for="date-doc" class="title-field">Дата
              <VueDatePicker
                  model-type="yyyy-MM-dd"
                  placeholder="00.00.0000"
                  class="field field--calendar"
                  v-model="uploadDocument.date"/>
            </label>
            <label for="public-doc" class="title-field">Публичный
              <select v-model="uploadDocument.visibility" class="field field--select">
                <option :value="1">Да</option>
                <option :value="0">Нет</option>
              </select>
            </label>

            <label for="file-doc" class="title-field title-field--relative">Файл документа
              <div class="label-add-file transparent" v-bind="getRootProps()">
                <input v-bind="getInputProps()" :multiple="false" @change="onAddDocFile">
                <span class="field field--file" v-if="!docFile || !docFile.name">Выберите или перетащите сюда файл (максимум 25Мб)</span>
                <span class="field field--file" v-else>{{ docFile.name }}</span>
              </div>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear" @click="Shared.closeModal('#add-document-rgo')">отменить
              </button>
              <button type="submit" class="button" @click="uploadRGODocument">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="edit-main-rfrp">
        <div class="popup">
          <div class="modal-title title-h3">Редактирование основной информации о РФРП</div>
          <div class="modal-body">
            <label for="rfrp-site" class="title-field">Сайт
              <input class="field" type="text" v-model="fund.siteUrl" placeholder="Введите сайт">
            </label>
            <label for="rfrp-email" class="title-field">Общий e-mail
              <input class="field" type="text" v-model="fund.generalEmail" id=""
                     placeholder="Введите адрес электронной почты">
            </label>
            <label for="rfrp-phone" class="title-field">Общий телефон
              <input class="field" type="text" v-model="fund.generalPhone" id="" placeholder="Введите телефонный номер">
            </label>
            <label for="rfrp-opf" class="title-field">Организационно-правовая форма
              <select v-model="fund.legalFormTypes" class="field field--select">
                <option :value="i.value" v-for="i in legalForms" :key="i.value">{{ i.title }}</option>
              </select>
            </label>
            <label for="rfrp-address" class="title-field">Фактический адрес
              <input class="field" type="text" v-model="fund.actualAddress" id="" placeholder="Введите адрес">
            </label>
            <label for="rfrp-founder" class="title-field">Учредитель РФРП
              <input class="field" type="text" v-model="fund.legalAddress" placeholder="Введите учредителя РФРП">
            </label>
            <label for="rfrp-requisites" class="title-field">Реквизиты НПА о создании РФРП
              <input class="field" type="text" v-model="fund.npaRequisites" placeholder="Выберите из списка">
            </label>
            <label for="rfrp-date" class="title-field">Дата подписания соглашения
              <VueDatePicker placeholder="00.00.0000" class="field field--calendar"
                             v-model="fund.agreementSigningDate"/>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear" @click="Shared.closeModal('#edit-main-rfrp')">отменить
              </button>
              <button type="submit" class="button" @click="saveMainRFRP()">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="edit-main-rgo">
        <div class="popup">
          <div class="modal-title title-h3">Редактирование основной информации о РГО</div>
          <div class="modal-body">
            <label for="rgo-name" class="title-field">Название РГО
              <input class="field field--add-icon-green" type="text" name="rgo-name" id=""
                     placeholder="Введите название РГО">
            </label>
            <div class="table-container">
              <table class="table table--guarantee-capital">
                <thead>
                <tr>
                  <th>Год</th>
                  <th>Размер гарантийного капитала на г., млн руб.</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="i in fund.guaranteeAmounts" :key="i.year">
                  <td>{{ i.year }}</td>
                  <td><input class="field" type="text" v-model="i.amount"
                             placeholder="Введите размер гарантийного капитала на выбранный год">
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <label for="rgo-limit" class="title-field">Лимит на 1 заем, млн руб.
              <input class="field" type="text" v-model="fund.loanLimit" placeholder="Введите лимит на 1 заем">
            </label>
            <label for="rgo-site" class="title-field">Сайт
              <input class="field" type="text" v-model="fund.siteUrl" placeholder="Введите сайт">
            </label>
            <label for="rgo-email" class="title-field">Общий e-mail
              <input class="field" type="text" v-model="fund.generalEmail"
                     placeholder="Введите адрес электронной почты">
            </label>
            <label for="rgo-phone" class="title-field">Телефонный номер
              <input class="field" type="text" v-model="fund.generalPhone" placeholder="Введите телефонный номер">
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear"
                      @click="Shared.closeModal('#edit-main-rgo')">отменить
              </button>
              <button type="submit" class="button" @click="saveMainRGO">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="create-main-rgo">
        <div class="popup">
          <div class="modal-title title-h3">Добавление основной информации о РГО</div>
          <div class="modal-body">
            <label for="rgo-name" class="title-field">Название РГО
              <Multiselect class="c-input c-select c-filter__input c-multiselect-filter" v-model="fund.counterparty"
                           mode="single" :loading="isLoading" @search-change="counterpartySearch" :min-chars="10"
                           :show-no-results="false" :searchable="true" :options="counterpartyList" placeholder="РГО"
                           :no-options-text="'Укажите ОГРН или ИНН'"/>
            </label>
            <div class="table-container">
              <table class="table table--guarantee-capital">
                <thead>
                <tr>
                  <th>Год</th>
                  <th>Размер гарантийного капитала на г., млн руб.</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="i in fund.rgoGuaranteeAmounts" :key="i.key">
                  <td>{{ i.key }}</td>
                  <td><input class="field" type="text" v-model="i.value"
                             placeholder="Введите размер гарантийного капитала на выбранный год">
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <label for="rgo-limit" class="title-field">Лимит на 1 заем, млн руб.
              <input class="field" type="text" v-model="fund.loanLimit" placeholder="Введите лимит на 1 заем">
            </label>
            <label for="rgo-site" class="title-field">Сайт
              <input class="field" type="text" v-model="fund.siteUrl" placeholder="Введите сайт">
            </label>
            <label for="rgo-email" class="title-field">Общий e-mail
              <input class="field" type="text" v-model="fund.generalEmail"
                     placeholder="Введите адрес электронной почты">
            </label>
            <label for="rgo-phone" class="title-field">Телефонный номер
              <input class="field" type="text" v-model="fund.generalPhone" placeholder="Введите телефонный номер">
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="reset" class="button button--clear"
                      @click="Shared.closeModal('#create-main-rgo')">отменить
              </button>
              <button type="submit" class="button" @click="saveCreateMainRGO">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="add-contact">
        <div class="popup">
          <div class="modal-title title-h2">Добавление контактного лица РГО</div>
          <div class="modal-body">
            <label for="fio-rgo" class="title-field">ФИО*
              <input class="field" type="text" v-model="contact.fullName" placeholder="Введите ФИО">
            </label>
            <label for="position-rgo" class="title-field">Должность*
              <input class="field" type="text" v-model="contact.occupation" placeholder="Введите должность">
            </label>
            <label for="phone-rgo" class="title-field">Номер телефона*
              <input class="field" type="text" v-model="contact.phone" placeholder="Введите номер телефона">
            </label>
            <label for="email-rgo" class="title-field">E-mail*
              <input class="field" type="text" v-model="contact.email" placeholder="Введите адрес электронной почты">
            </label>
            <label for="comment-rgo" class="title-field">Комментарий
              <textarea class="field field--textarea" v-model="contact.comment"
                        placeholder="Введите комментарий"></textarea>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear"
                      @click="Shared.closeModal('#add-contact')">закрыть
              </button>
              <button type="submit" class="button" @click="saveNewContact">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="edit-contact">
        <div class="popup">
          <div class="modal-title title-h2">Редактирование контактного лица РГО</div>
          <div class="modal-body">
            <label for="fio-rgo" class="title-field">ФИО*
              <input class="field" type="text" v-model="contact.fullName" placeholder="Введите ФИО">
            </label>
            <label for="position-rgo" class="title-field">Должность*
              <input class="field" type="text" v-model="contact.occupation" placeholder="Введите должность">
            </label>
            <label for="phone-rgo" class="title-field">Номер телефона*
              <input class="field" type="text" v-model="contact.phone" placeholder="Введите номер телефона">
            </label>
            <label for="email-rgo" class="title-field">E-mail*
              <input class="field" type="text" v-model="contact.email" placeholder="Введите адрес электронной почты">
            </label>
            <label for="comment-rgo" class="title-field">Комментарий
              <textarea class="field field--textarea" v-model="contact.comment"
                        placeholder="Введите комментарий"></textarea>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="submit" class="button button--delete" @click="showRemoveContact">Удалить</button>
              <button type="reset" class="button button--clear"
                      @click="Shared.closeModal('#edit-contact')">отменить
              </button>
              <button type="submit" class="button" @click="saveContact">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="add-contact-npa">
        <div class="popup">
          <div class="modal-title title-h2">Добавление контактного лица СПИК</div>
          <div class="modal-body">
            <label for="fio-rgo" class="title-field">НПА*
              <select v-model="contact.npa" class="field field--select">
                <option :value="i" :key="i.id" v-for="i in item.npaRegistry">{{ i.npaTitle }}</option>
              </select>
            </label>
            <label for="fio-rgo" class="title-field">ФИО*
              <input class="field" type="text" v-model="contact.fullName" placeholder="Введите ФИО">
            </label>
            <label for="position-rgo" class="title-field">Должность*
              <input class="field" type="text" v-model="contact.occupation" placeholder="Введите должность">
            </label>
            <label for="phone-rgo" class="title-field">Номер телефона*
              <input class="field" type="text" v-model="contact.phone" placeholder="Введите номер телефона">
            </label>
            <label for="email-rgo" class="title-field">E-mail*
              <input class="field" type="text" v-model="contact.email" placeholder="Введите адрес электронной почты">
            </label>
            <label for="comment-rgo" class="title-field">Комментарий
              <textarea class="field field--textarea" v-model="contact.comment"
                        placeholder="Введите комментарий"></textarea>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear"
                      @click="Shared.closeModal('#add-contact-npa')">закрыть
              </button>
              <button type="submit" class="button" @click="saveNPANewContact">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="edit-contact-npa">
        <div class="popup">
          <div class="modal-title title-h2">Редактирование контактного лица РГО</div>
          <div class="modal-body">
            <label for="fio-rgo" class="title-field">ФИО*
              <input class="field" type="text" v-model="contact.fullName" placeholder="Введите ФИО">
            </label>
            <label for="position-rgo" class="title-field">Должность*
              <input class="field" type="text" v-model="contact.occupation" placeholder="Введите должность">
            </label>
            <label for="phone-rgo" class="title-field">Номер телефона*
              <input class="field" type="text" v-model="contact.phone" placeholder="Введите номер телефона">
            </label>
            <label for="email-rgo" class="title-field">E-mail*
              <input class="field" type="text" v-model="contact.email" placeholder="Введите адрес электронной почты">
            </label>
            <label for="comment-rgo" class="title-field">Комментарий
              <textarea class="field field--textarea" v-model="contact.comment"
                        placeholder="Введите комментарий"></textarea>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="submit" class="button button--delete" @click="showNPARemoveContact">Удалить</button>
              <button type="reset" class="button button--clear"
                      @click="Shared.closeModal('#edit-contact')">отменить
              </button>
              <button type="submit" class="button" @click="saveNPAContact">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="edit-document">
        <div class="popup">
          <div class="modal-title title-h3">Редактирование документа</div>
          <div class="modal-body">
            <label for="doc-type" class="title-field">Тип документа
              <input class="field" type="text" v-model="uploadDocument.typeName" placeholder="Введите тип документа">
            </label>
            <label for="doc-number" class="title-field">Номер документа
              <input class="field" type="text" v-model="uploadDocument.number" placeholder="Введите номер документа">
            </label>
            <label for="date-doc" class="title-field">Дата
              <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                             v-model="uploadDocument.date"/>
            </label>
            <label for="public-doc" class="title-field">Публичный
              <select v-model="uploadDocument.visibility" class="field field--select">
                <option :value="1">Да</option>
                <option :value="0">Нет</option>
              </select>
            </label>

            <label for="file-doc" class="title-field title-field--relative">Файл документа
              <div class="label-add-file transparent" v-bind="getRootProps()">
                <input v-bind="getInputProps()" :multiple="false" @change="onAddDocFile">
                <span class="field field--file" v-if="!docFile || !docFile.name">{{ uploadDocument.name }}</span>
                <span class="field field--file" v-else>{{ docFile.name }}</span>
              </div>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="submit" class="button button--delete"
                      @click="showDelDocument(uploadDocument)">Удалить
              </button>
              <button type="reset" class="button button--clear"
                      @click="Shared.closeModal('#edit-document')">отменить
              </button>
              <button type="submit" class="button" @click="updateRFRPDocument">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="edit-document-region">
        <div class="popup">
          <div class="modal-title title-h3">Редактирование документа</div>
          <div class="modal-body">
            <label for="doc-type" class="title-field">Тип документа
              <input class="field" type="text" v-model="uploadDocument.typeName" placeholder="Введите тип документа">
            </label>
            <label for="doc-number" class="title-field">Номер документа
              <input class="field" type="text" v-model="uploadDocument.number" placeholder="Введите номер документа">
            </label>
            <label for="date-doc" class="title-field">Дата
              <VueDatePicker model-type="yyyy-MM-dd" placeholder="00.00.0000" class="field field--calendar"
                             v-model="uploadDocument.date"/>
            </label>
            <label for="public-doc" class="title-field">Публичный
              <select v-model="uploadDocument.visibility" class="field field--select">
                <option :value="1">Да</option>
                <option :value="0">Нет</option>
              </select>
            </label>

            <label for="file-doc" class="title-field title-field--relative">Файл документа
              <div class="label-add-file transparent" v-bind="getRootProps()">
                <input v-bind="getInputProps()" :multiple="false" @change="onAddDocFile">
                <span class="field field--file" v-if="!docFile || !docFile.name">{{ uploadDocument.name }}</span>
                <span class="field field--file" v-else>{{ docFile.name }}</span>
              </div>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="submit" class="button button--delete"
                      @click="showDelDocument(uploadDocument)">Удалить
              </button>
              <button type="reset" class="button button--clear"
                      @click="Shared.closeModal('#edit-document-region')">отменить
              </button>
              <button type="submit" class="button" @click="updateRegionDocument">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="edit-document-rgo">
        <div class="popup">
          <div class="modal-title title-h3">Редактирование документа</div>
          <div class="modal-body">
            <label for="doc-type" class="title-field">Тип документа
              <input class="field" type="text" v-model="uploadDocument.typeName" placeholder="Введите тип документа">
            </label>
            <label for="doc-number" class="title-field">Номер документа
              <input class="field" type="text" v-model="uploadDocument.number"
                     placeholder="Введите номер документа">
            </label>
            <label for="date-doc" class="title-field">Дата
              <VueDatePicker
                  model-type="yyyy-MM-dd"
                  placeholder="00.00.0000"
                  class="field field--calendar"
                  v-model="uploadDocument.date"/>
            </label>
            <label for="public-doc" class="title-field">Публичный
              <select v-model="uploadDocument.visibility" class="field field--select">
                <option :value="1">Да</option>
                <option :value="0">Нет</option>
              </select>
            </label>

            <label for="file-doc" class="title-field title-field--relative">Файл документа
              <div class="label-add-file transparent" v-bind="getRootProps()">
                <input v-bind="getInputProps()" :multiple="false" @change="onAddDocFile">
                <span class="field field--file" v-if="!docFile || !docFile.name">{{ uploadDocument.name }}</span>
                <span class="field field--file" v-else>{{ docFile.name }}</span>
              </div>
            </label>
          </div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="submit" class="button button--delete" @click="showDelDocument(uploadDocument)">Удалить
              </button>
              <button type="reset" class="button button--clear" @click="Shared.closeModal('#edit-document-rgo')">
                отменить
              </button>
              <button type="submit" class="button" @click="updateRGODocument">СОХРАНИТЬ</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="del-document">
        <div class="popup">
          <div class="modal-title">Удаление записи</div>
          <div class="modal-body">Вы действительно хотите удалить совместную программу?</div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear" @click="Shared.closeModal('#del-document')">Закрыть
              </button>
              <button type="submit" class="button button--delete" @click="removeEditСollabPrograms">Удалить</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="del-document2">
        <div class="popup">
          <div class="modal-title">Удаление записи</div>
          <div class="modal-body">Вы действительно хотите удалить документ?</div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear" @click="Shared.closeModal('#del-document2')">Закрыть
              </button>
              <button type="submit" class="button button--delete" @click="removeDocument">Удалить</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="del-document-region">
        <div class="popup">
          <div class="modal-title">Удаление записи</div>
          <div class="modal-body">Вы действительно хотите удалить документ?</div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear"
                      @click="Shared.closeModal('#del-document-region')">Закрыть
              </button>
              <button type="submit" class="button button--delete" @click="removeRegionDocument">Удалить</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="del-document-rgo">
        <div class="popup">
          <div class="modal-title">Удаление записи</div>
          <div class="modal-body">Вы действительно хотите удалить документ?</div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear" @click="Shared.closeModal('#del-document-rgo')">Закрыть
              </button>
              <button type="submit" class="button button--delete" @click="removeRGODocument">Удалить</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="close-document">
        <div class="popup">
          <div class="modal-title">Запись удалена</div>
          <div class="modal-body"></div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button" @click="Shared.closeModal('#close-document')">Закрыть</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="close-document2">
        <div class="popup">
          <div class="modal-title">Документ удален</div>
          <div class="modal-body"></div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button" @click="Shared.closeModal('#close-document2')">Закрыть</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="del-document">
        <div class="popup">
          <div class="modal-title">Удаление документа</div>
          <div class="modal-body">Вы действительно хотите удалить НПА?</div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear">Закрыть</button>
              <button type="submit" class="button button--delete">Удалить</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="close-document">
        <div class="popup">
          <div class="modal-title">Документ удален</div>
          <div class="modal-body"></div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button">Закрыть</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="del-contact">
        <div class="popup">
          <div class="modal-title">Удаление контакта</div>
          <div class="modal-body">Вы действительно хотите удалить контакт?</div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear"
                      @click="Shared.closeModal('#del-contact')">Закрыть
              </button>
              <button type="submit" class="button button--delete" @click="removeContact">Удалить</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="del-contact-npa">
        <div class="popup">
          <div class="modal-title">Удаление контакта</div>
          <div class="modal-body">Вы действительно хотите удалить контакт?</div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button button--clear"
                      @click="Shared.closeModal('#del-contact-npa')">Закрыть
              </button>
              <button type="submit" class="button button--delete" @click="removeNPAContact">Удалить</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-overlay" id="close-contact">
        <div class="popup">
          <div class="modal-title">Контакт удален</div>
          <div class="modal-body"></div>
          <div class="modal-bottom">
            <div class="buttons-block">
              <button type="button" class="button" @click="Shared.closeModal('#close-contact')">Закрыть</button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped></style>