<script setup>
import MainFooter from "@/components/partial/MainFooter.vue";
import { ref, computed, watch, onMounted } from "vue";
import Shared from "@/common/shared";
import { useRoute } from "vue-router";
import { Dictionary, Regions, Capitalization } from "@/common/api.service";

const route = useRoute();
const years = ref([]);
const funds = ref([]);
const selectedFund = ref([
    ...(route.params.fund ? [route.params.fund] : [])
]);
const currentYear = new Date().getFullYear();
const selectedYear = ref([]);
const selectedFundDetails = ref({ id: "", title: "" });

const selectedFundId = computed(() => {
    return selectedFund.value;
});

const dataList = ref({
    capitalization: {
        rows: [
            {
                code: "",
                title: "",
                quarter1: 0,
                quarter2: 0,
                quarter3: 0,
                quarter4: 0,
                modifiedAt: null,
                modifiedBy: {
                    id: "",
                    email: "",
                    firstName: "",
                    lastName: "",
                    middleName: "",
                    cellular: "",
                    phone: "",
                    phoneExtention: "",
                    shortName: ""
                },
                level: 0,
                editable: true
            }
        ],
        total: {
            code: "",
            title: "",
            quarter1: 0,
            quarter2: 0,
            quarter3: 0,
            quarter4: 0,
            modifiedAt: null,
            modifiedBy: {
                id: "",
                email: "",
                firstName: "",
                lastName: "",
                middleName: "",
                cellular: "",
                phone: "",
                phoneExtention: "",
                shortName: ""
            },
            level: 0,
            editable: true
        }
    },
    budget: {
        rows: [
            {
                rows: [
                    {
                        code: "",
                        title: "",
                        value: 0,
                        createdAt: null,
                        createdBy: {
                            id: "",
                            email: "",
                            firstName: "",
                            lastName: "",
                            middleName: "",
                            cellular: "",
                            phone: "",
                            phoneExtention: "",
                            shortName: ""
                        },
                        history: [
                            {
                                code: "",
                                title: "",
                                value: 0,
                                createdAt: null,
                                createdBy: {
                                    id: "",
                                    email: "",
                                    firstName: "",
                                    lastName: "",
                                    middleName: "",
                                    cellular: "",
                                    phone: "",
                                    phoneExtention: "",
                                    shortName: ""
                                }
                            }
                        ]
                    }
                ],
                total: {
                    code: "",
                    title: "",
                    value: 0,
                    createdAt: null,
                    createdBy: {
                        id: "",
                        email: "",
                        firstName: "",
                        lastName: "",
                        middleName: "",
                        cellular: "",
                        phone: "",
                        phoneExtention: "",
                        shortName: ""
                    },
                    history: [
                        {
                            code: "",
                            title: "",
                            value: 0,
                            createdAt: null,
                            createdBy: {
                                id: "",
                                email: "",
                                firstName: "",
                                lastName: "",
                                middleName: "",
                                cellular: "",
                                phone: "",
                                phoneExtention: "",
                                shortName: ""
                            }
                        }
                    ]
                },
                year: 0
            }
        ],
        year: 0,
        ofYears: [
            0
        ]
    }
});

const row = ref({
    year: null,
    code: "",
    quarter1: null,
    quarter2: null,
    quarter3: null,
    quarter4: null
});

const updateSelectedFundDetails = (newFund) => {
    const fund = funds.value.find(item => item.fund.id === newFund);
    if (fund) {
        selectedFundDetails.value = { id: fund.fund.id, title: fund.fund.title };
    }
};

async function filteredCapitalizationData() {
    await Capitalization.FilteredCapitalizationData(selectedFund.value, selectedYear.value)
        .then(({ data }) => {
            dataList.value = data;
        })
        .catch(() => {
        });
}

await Dictionary.fundCapitalizationYears()
    .then(({ data }) => {
        years.value = data;
    })
    .catch(() => { });

await Regions.list()
    .then(({ data }) => {
        funds.value = data;
        if (selectedFund.value && selectedFund.value.length > 0) {
            updateSelectedFundDetails(selectedFund.value[0]);
        }
    })
    .catch(() => {
    });

const yearsList = computed(() => {
    let r = [];
    years.value.forEach(function (year) {
        r.push({ value: year.value, label: year.title });
    });
    return r;
});

const fundsList = computed(() => {
    let r = [];
    funds.value.forEach(function (item) {
        r.push({ value: item.fund.id, label: item.fund.title });
    });
    return r;
});

onMounted(() => {
    if (selectedFund.value.length > 0) {
        const defaultYear = years.value.find(year => year.value === currentYear);
        if (defaultYear) {
            selectedYear.value = [defaultYear.value];
        } else {
            selectedYear.value = [];
        }
    }
});

if (selectedFund.value.length > 0 && selectedYear.value.length > 0) {
    await filteredCapitalizationData();
}

watch([selectedYear, selectedFund], async ([newYear, newFund]) => {

    newYear = Array.isArray(newYear) ? newYear : (newYear != null ? [newYear] : []);
    newFund = Array.isArray(newFund) ? newFund : (newFund != null ? [newFund] : []);

    if (newFund.length > 0 && newYear.length > 0) {
        await filteredCapitalizationData();
    }
});


watch(selectedFundId, (newFund) => {
    if (!newFund || newFund.length === 0) {
        selectedFundDetails.value = { id: "", title: "" };
    } else {
        updateSelectedFundDetails(newFund);
    }
});

function updateRowData(index) {
    const capitalizationRow = dataList.value.capitalization.rows[index];
    row.value = {
        year: dataList.value.budget.year,
        code: capitalizationRow.code,
        quarter1: capitalizationRow.quarter1,
        quarter2: capitalizationRow.quarter2,
        quarter3: capitalizationRow.quarter3,
        quarter4: capitalizationRow.quarter4
    };
}

async function showEditCapitalization() {
    Shared.showModal('#edit-capitalization');
}

async function updateCapitalization() {
    await Capitalization.updateCapitalization(selectedFund.value, {
        year: row.value.year,
        code: row.value.code,
        quarter1: row.value.quarter1,
        quarter2: row.value.quarter2,
        quarter3: row.value.quarter3,
        quarter4: row.value.quarter4
    });
    Shared.closeModal('#edit-capitalization');
}

</script>

<template>
    <div class="wrapper">
        <MainHeader></MainHeader>
        <main class="page page--js">
            <div class="breadcrumbs-container">
                <div class="container">
                    <div class="breadcrumbs-wrapper">
                        <ul class="breadcrumbs">
                            <li><router-link :to="{ name: 'ListRegions' }">Модуль регионов</router-link></li>
                            <li><router-link :to="{ name: 'CapitalizationTableWithoutFund' }">Капитализация
                                    РФРП</router-link></li>
                            <li>
                                <router-link v-if="selectedFundDetails.id"
                                    :to="{ name: 'CardRegions', params: { fund: selectedFundDetails.id } }">
                                    {{ selectedFundDetails.title }}
                                </router-link>
                                <span v-else>Выберите Фонд из списка</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="filters-container">
                <div class="container">
                    <div class="filters">
                        <div class="filters__body">
                            <Multiselect class="field field--select field--m-width-265 margin-0" v-model="selectedYear"
                                mode="single" :options="yearsList" :show-no-results="false" :searchable="true"
                                placeholder="Год" :no-options-text="'Укажите год'" />
                            <Multiselect class="field field--select field--m-width-265 margin-0" v-model="selectedFund"
                                mode="single" :options="fundsList" :show-no-results="false" :searchable="true"
                                placeholder="Наименование РФРП" :no-options-text="'Укажите РФРП'" />
                        </div>
                        <div class="filters__text">Шаблон для заполнения информации по самостоятельным займам</div>
                    </div>
                </div>
            </div>
            <div class="content" v-if="selectedFund && selectedYear">
                <div class="table-responsive table-responsive--padding table-responsive--bg-color-white">
                    <table class="table capitalisation">
                        <thead>
                            <tr>
                                <th>Показатель</th>
                                <th colspan="5">Значение</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="title-row">
                                <td>1. КАПИТАЛИЗАЦИЯ</td>
                                <td colspan="5">По состоянию на {{ dataList.budget.year }} год</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>31.03</td>
                                <td>30.06</td>
                                <td>30.09</td>
                                <td>31.12</td>
                                <td></td>
                            </tr>
                            <tr v-for="(row, index) in dataList.capitalization.rows" :key="index" :class="{
                                'table-first-level': row.level === 1,
                                'table-second-level': row.level === 2,
                                'table-third-level': row.level === 3
                            }">
                                <td class="title-td">{{ row.code }} {{ row.title }}</td>
                                <td>{{ row.quarter1 }}</td>
                                <td>{{ row.quarter2 }}</td>
                                <td>{{ row.quarter3 }}</td>
                                <td>{{ row.quarter4 }}</td>
                                <td>
                                    <div class="table-button-block">
                                        <button type="button" class="button-pen" v-if="row.editable"
                                            @click="updateRowData(index); showEditCapitalization()"></button>
                                    </div>
                                </td>
                            </tr>
                            <tr class="total-row">
                                <td>{{ dataList.capitalization.total.code }} {{ dataList.capitalization.total.title }}</td>
                                <td>{{ dataList.capitalization.total.quarter1 }}</td>
                                <td>{{ dataList.capitalization.total.quarter2 }}</td>
                                <td>{{ dataList.capitalization.total.quarter3 }}</td>
                                <td>{{ dataList.capitalization.total.quarter4 }}</td>
                                <td></td>
                            </tr>
                            <tr class="buttons-row">
                                <td>
                                    Отчет о капитализации и бюджете (<a
                                        href="https://bod.frprf.ru/public/documents/pismo-rfrp-po-kapitalizacii-i-byudzhetu"
                                        download class="link link--color-main link--fs12">шаблон отчета</a>)
                                </td>
                                <td>
                                    <div class="buttons-column">
                                        <button class="button table-btn-green">Загрузить</button>
                                        <button class="button table-btn-green">Обновить</button>
                                        <button type="button" class="link link--color-main button-download">
                                            Скачать
                                        </button>
                                    </div>
                                </td>
                                <td>
                                    <div class="buttons-column">
                                        <button class="button table-btn-green">Загрузить</button>
                                        <button class="button table-btn-green">Обновить</button>
                                        <button type="button" class="link link--color-main button-download">
                                            Скачать
                                        </button>
                                    </div>
                                </td>
                                <td>
                                    <div class="buttons-column">
                                        <button class="button table-btn-green">Загрузить</button>
                                        <button class="button table-btn-green">Обновить</button>
                                        <button type="button" class="link link--color-main button-download">
                                            Скачать
                                        </button>
                                    </div>
                                </td>
                                <td>
                                    <div class="buttons-column">
                                        <button class="button table-btn-green">Загрузить</button>
                                        <button class="button table-btn-green">Обновить</button>
                                        <button type="button" class="link link--color-main button-download">
                                            Скачать
                                        </button>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                            <tr class="title-row">
                                <td>2. БЮДЖЕТ на выдачу займов, в т.ч. план на текущий год</td>
                                <td colspan="2">{{ dataList.budget.ofYears[0] }} год</td>
                                <td colspan="2">{{ dataList.budget.ofYears[1] }} год</td>
                            </tr><!--
                            <tr v-for="(row, index) in rows" :key="index">
                                <td>{{ row.code }} {{ row.title }}</td>
                                <td colspan="2">{{ row.value[0] }}</td>
                                <td colspan="3">{{ row.value[1] }}</td>
                                <td>
                                    <div class="table-button-block">
                                        <button type="button" class="button-pen"
                                            @click="updateBudgetRows(index); showEditBudget()"></button>
                                        <button type="button" class="button-history"></button>
                                    </div>
                                </td>
                            </tr>-->
                            <tr class="total-row">
                                <td>{{ budgetData.rows[0].total.title }}</td>
                                <td colspan="2">{{ budgetData.rows[0].total.value }}</td>
                                <td colspan="3">{{ budgetData.rows[1].total.value }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-overlay" id="edit-capitalization">
                <div class="popup">
                    <div class="modal-title title-h3">Редактирование капитализации</div>
                    <div class="modal-body">
                        <label for="row-year" class="title-field">Год
                            <input class="field" type="text" v-model="row.year" placeholder="Введите год">
                        </label>
                        <label for="row-code" class="title-field">Код строки
                            <input class="field" type="text" v-model="row.code" placeholder="Введите код строки">
                        </label>
                        <label for="row-quarter1" class="title-field">Данные за 1 квартал
                            <input class="field" type="text" v-model="row.quarter1"
                                placeholder="Введите данные за 1 квартал">
                        </label>
                        <label for="row-quarter2" class="title-field">Данные за 2 квартал
                            <input class="field" type="text" v-model="row.quarter2"
                                placeholder="Введите данные за 2 квартал">
                        </label>
                        <label for="row-quarter3" class="title-field">Данные за 3 квартал
                            <input class="field" type="text" v-model="row.quarter3"
                                placeholder="Введите данные за 3 квартал">
                        </label>
                        <label for="row-quarter4" class="title-field">Данные за 4 квартал
                            <input class="field" type="text" v-model="row.quarter4"
                                placeholder="Введите данные за 4 квартал">
                        </label>
                    </div>
                    <div class="modal-bottom">
                        <div class="buttons-block">
                            <button type="reset" class="button button--clear"
                                @click="Shared.closeModal('#edit-capitalization')">отменить</button>
                            <button type="submit" class="button" @click="updateCapitalization">СОХРАНИТЬ</button>
                        </div>
                    </div>
                </div>
            </div>
            <!--
            <div class="modal-overlay" id="edit-budget">
                <div class="popup">
                    <div class="modal-title title-h3">Редактирование бюджета на выдачу займов</div>
                    <div class="modal-body">
                        <div v-for="(item, index) in budgetItem" :key="index">
                            <label for="row-year" class="title-field">Год
                                <input class="field" type="text" v-model="item.year" placeholder="Введите год" readonly>
                            </label>
                            <label for="row-code" class="title-field">Код строки
                                <input class="field" type="text" v-model="item.code" placeholder="Введите код" readonly>
                            </label>
                            <label for="row-value" class="title-field">Данные
                                <input class="field" type="text" v-model="item.value" placeholder="Введите данные">
                            </label>
                        </div>
                    </div>
                    <div class="modal-bottom">
                        <div class="buttons-block">
                            <button type="reset" class="button button--clear"
                                @click="Shared.closeModal('#edit-budget')">отменить</button>
                            <button type="submit" class="button">СОХРАНИТЬ</button>
                        </div>
                    </div>
                </div>
            </div>
            -->
            <!--
            <div class="modal-overlay" id="budget-history">
                <div class="popup">
                    <div class="modal-title title-h3">История редактирования бюджета на выдачу займов</div>
                    <div class="modal-body">
                        <div v-for="(item, index) in budgetItem" :key="index">
                            <label for="row-year" class="title-field">Год
                                <input class="field" type="text" v-model="item.year" placeholder="Введите год" readonly>
                            </label>
                        </div>
                    </div>
                    <div class="modal-bottom">
                        <div class="buttons-block">
                            <button type="button" class="button"
                                @click="Shared.closeModal('#budget-history')">закрыть</button>
                        </div>
                    </div>
                </div>
            </div>
            -->
            <!--
            <div class="modal-overlay" id="add-document">
                <div class="popup">
                    <div class="modal-title title-h3">Добавление нового документа</div>
                    <div class="modal-body">
                        <label for="file-doc" class="title-field title-field--relative">Файл документа
                            <div class="label-add-file transparent" v-bind="getRootProps()">
                                <input v-bind="getInputProps()" :multiple="false" @change="onAddDocFile">
                                <span class="field field--file" v-if="!docFile || !docFile.name">Выберите или перетащите
                                    сюда файл
                                    (максимум 25Мб)</span>
                                <span class="field field--file" v-else>{{ docFile.name }}</span>
                            </div>
                        </label>
                    </div>
                    <div class="modal-bottom">
                        <div class="buttons-block">
                            <button type="reset" class="button button--clear"
                                @click="Shared.closeModal('#add-document')">отменить
                            </button>
                            <button type="submit" class="button" @click="uploadNewDocument">СОХРАНИТЬ</button>
                        </div>
                    </div>
                </div>
            </div>
            -->
            <!--
            <div class="modal-overlay" id="update-document">
                <div class="popup">
                    <div class="modal-title title-h3">Обновление документа</div>
                    <div class="modal-body">
                        <label for="file-doc" class="title-field title-field--relative">Файл документа
                            <div class="label-add-file transparent" v-bind="getRootProps()">
                                <input v-bind="getInputProps()" :multiple="false" @change="onAddDocFile">
                                <span class="field field--file" v-if="!docFile || !docFile.name">Выберите или перетащите
                                    сюда файл
                                    (максимум 25Мб)</span>
                                <span class="field field--file" v-else>{{ docFile.name }}</span>
                            </div>
                        </label>
                    </div>
                    <div class="modal-bottom">
                        <div class="buttons-block">
                            <button type="reset" class="button button--clear"
                                @click="Shared.closeModal('update-document')">отменить
                            </button>
                            <button type="submit" class="button" @click="updateDocument">СОХРАНИТЬ</button>
                        </div>
                    </div>
                </div>
            </div>
            -->
        </main>
        <MainFooter></MainFooter>
    </div>
</template>

<style scoped></style>
