<script setup>

import MainFooter from "@/components/partial/MainFooter.vue";
import {Gateway, Regions} from "@/common/api.service";
import {computed, onMounted, ref} from "vue";
import { useRouter } from "vue-router";
import {useStore} from "vuex";

const router = useRouter()
const store = useStore()
const list = ref([]);
const fl = ref({
  region: "",
  regionalExpertName: "",
  district: ""
})
const s = ref({
  region: "",
  regionalExpertName: "",
  district: ""
})
const projectManagers = ref([])
const districts = ref([])

async function fetchList() {
  await Regions.list({})
    .then(({ data }) => {
      list.value = data
    }).catch(async () => {
      //await router.push({ name: "Error" });
    })
}

const filteredList = computed(() => {
  let r = list.value
  if (fl.value.region !== "") {
    r = r.filter(v => v.id === fl.value.region)
  }
  if (fl.value.regionalExpertName !== "") {
    r = r.filter(v => v.regionalExpertName === fl.value.regionalExpertName)
  }
  if (fl.value.district !== "") {
    r = r.filter(v => v.district.title === fl.value.district)
  }

  return r
})

const regionList = computed(() => {
  let r = []
  list.value.forEach(function (f) {
    r.push({ value: f.id, label: `${f.title}` })
  })
  return r
})

await Regions.projectManagers()
  .then(({ data }) => {
    data.forEach(function (f) {
      projectManagers.value.push({ value: f.shortName, label: `${f.shortName}` })
    })
  }).catch(async (reason) => {
      await router.push({ name: "Error", query: reason });
    })

await Regions.districts()
  .then(({ data }) => {
    data.forEach(function (f) {
      districts.value.push({ value: f.title, label: `${f.title}` })
    })
  }).catch(() => {
  })

function appendFilter() {
  fl.value = s.value
}
function clearFilter() {
  fl.value = {
    region: "",
    regionalExpertName: "",
    district: ""
  }
  s.value = {
    region: "",
    regionalExpertName: "",
    district: ""
  }
}

onMounted(async () => {
  await Gateway.Counterparty(store.getters.currentUser.profile.counterpartyInn).catch(async () => {
    //await router.push({ name: "Error" });
  })
  await fetchList()
})
</script>

<template>
  <div class="wrapper">
    <MainHeader></MainHeader>
    <main class="page page--js">
      <div class="breadcrumbs-container">
        <div class="container">
          <div class="breadcrumbs-wrapper">
            <ul class="breadcrumbs">
              <li><a href="#">Модуль регионов</a></li>
              <li>Список регионов</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container">
          <div class="content__wrapper">
            <form action="#" class="region-list__form">
              <div class="region-list__form-body">
                <Multiselect class="field field--select field--m-width-265 margin-0" v-model="s.region" mode="single"
                             :options="regionList" :show-no-results="false" :searchable="true" placeholder="Регион ФРП"
                             :no-options-text="'Укажите регион'" />
                <Multiselect class="field field--select field--m-width-265 margin-0" v-model="s.regionalExpertName" mode="single"
                             :options="projectManagers" :show-no-results="false" :searchable="true" placeholder="Эксперт"
                             :no-options-text="'Укажите эксперта'" />
                <Multiselect class="field field--select field--m-width-265 margin-0" v-model="s.district" mode="single"
                             :options="districts" :show-no-results="false" :searchable="true" placeholder="Регион ФРП"
                             :no-options-text="'Укажите округ'" />
                <div class="region-list__counter hidden">Всего элементов: 4</div>
              </div>
              <div class="buttons-block">
                <button type="reset" class="button button--clear" @click="clearFilter">Очистить фильтры</button>
                <button type="button" class="button" @click="appendFilter">Поиск</button>
              </div>
            </form>
            <div class="table-responsive">
              <table class="table region-list">
                <thead>
                  <tr>
                    <th>Название региона</th>
                    <th>Название РФРП</th>
                    <th>Менеджер от департамента проектов</th>
                    <th>Эксперт по региону</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in filteredList" :key="item.id">
                    <td><a href="#" class="link link--big"></a>
                      <router-link :to="{ name: 'CardRegions', params: { fund: item.fund.id } }" class="link link--big">
                        {{ item.title }}
                      </router-link>
                    </td>
                    <td>{{ item.fund.title }}
                    </td>
                    <td>{{ item.projectManagerName }}</td>
                    <td>{{ item.regionalExpertName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MainFooter></MainFooter>
  </div>
</template>

<style scoped></style>