<script setup>
import MainFooter from "@/components/partial/MainFooter.vue";
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Regulations, Regions, Dictionary } from "@/common/api.service";

const route = useRoute();
const router = useRouter();

const listNpa = ref([]);
const listFilter = ref({
    region: "",
    status: ""
});
const listSelected = ref({
    region: "",
    status: ""
});

const regions = ref([]);
const statutes = ref([]);

const selectedRegion = computed(() => {
    return route.params.region || "";
});

watch(selectedRegion, (newRegion) => {
    listSelected.value.region = newRegion;
});

async function fetchListNpa() {
    await Regulations.listNpa({})
        .then(({ data }) => {
            listNpa.value = data;
        })
        .catch(async () => {
            await router.push({ name: "Error" });
        });
}

const filteredListNpa = computed(() => {
    let r = listNpa.value;
    if (listFilter.value.region !== "") {
        r = r.filter(v => v.regionId === listFilter.value.region);
    }
    if (listFilter.value.status !== "") {
        r = r.filter(v => v.npaStatus === listFilter.value.status);
    }
    return r;
});

const regionList = computed(() => {
    let r = [];
    regions.value.forEach(function (region) {
        r.push({ value: region.id, label: region.title });
    });
    return r;
});

const statutesList = computed(() => {
    let r = [];
    statutes.value.forEach(function (status) {
        r.push({ value: status.value, label: status.title });
    });
    return r;
});

await Regions.list()
    .then(({ data }) => {
        regions.value = data;
    }).catch(() => {
    });

await Dictionary.regulationsStatuses()
    .then(({ data }) => {
        statutes.value = data;
    }).catch(() => {
    });

function moveFilter() {
    listFilter.value = listSelected.value;
}

function clearFilter() {
    console.log('Clearing filters...');
    listFilter.value = {
        region: "",
        status: ""
    };
    listSelected.value = {
        region: "",
        status: ""
    };
}

listSelected.value.region = selectedRegion.value;
if (selectedRegion.value) {
    moveFilter();
}
await fetchListNpa();
</script>



<template>
    <div class="wrapper">
        <MainHeader></MainHeader>
        <main class="page page--js">
            <div class="breadcrumbs-container">
                <div class="container">
                    <div class="breadcrumbs-wrapper">
                        <ul class="breadcrumbs">
                            <li><router-link :to="{ name: 'ListRegions' }">Модуль регионов
                                </router-link></li>
                            <li>Реестр НПА по СПИК</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="container">
                    <div class="content__wrapper">
                        <div class="content__title">
                            <h1 class="title title-h1">Реестр НПА по СПИК</h1>
                            <router-link v-if="listSelected.region && listSelected.region !== ''"
                                :to="{ name: 'AddCardNpa', query: { region: listSelected.region } }" class="button">
                                Добавить НПА
                            </router-link>
                            <router-link v-else :to="{ name: 'AddCardNpa' }" class="button">
                                Добавить НПА
                            </router-link>
                        </div>
                        <form action="#" class="region-list__form" @submit.prevent>
                            <div class="region-list__form-body">
                                <Multiselect class="field field--select field--m-width-300 margin-0"
                                    v-model="listSelected.region" mode="single" :options="regionList"
                                    :show-no-results="false" :searchable="true" placeholder="Наименование региона"
                                    :no-options-text="'Укажите регион'" />
                                <Multiselect class="field field--select field--m-width-265 margin-0"
                                    v-model="listSelected.status" mode="single" :options="statutesList"
                                    :show-no-results="false" :searchable="true" placeholder="Статус НПА"
                                    :no-options-text="'Укажите статус'" />
                            </div>
                            <div class="buttons-block">
                                <button type="reset" class="button button--clear" @click="clearFilter">Очистить
                                    фильтры</button>
                                <button type="button" class="button" @click="moveFilter">Поиск</button>
                            </div>
                        </form>
                        <div class="table-responsive">
                            <table class="table region-list region-list--npa">
                                <thead>
                                    <tr>
                                        <th>Название региона</th>
                                        <th>Тип</th>
                                        <th>Название</th>
                                        <th>Статус</th>
                                        <th>Ставка налога, %</th>
                                        <th>Комментарий</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in filteredListNpa" :key="item.id">
                                        <td>
                                            <router-link :to="{ name: 'ListRegions' }" class="link link--big">
                                                {{ item.regionTitle }}
                                            </router-link>
                                        </td>
                                        <td>{{ item.npaTypeText }}</td>
                                        <td>
                                            <router-link :to="{
                                                name: 'CardNpa',
                                                params: {
                                                    region: item.regionId,
                                                    title: item.id
                                                }
                                            }" class="link">
                                                {{ item.npaTitle }}
                                            </router-link>
                                        </td>
                                        <td>{{ item.npaStatusText }}</td>
                                        <td>{{ item.taxRate }}</td>
                                        <td>{{ item.comment }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <MainFooter></MainFooter>
    </div>
</template>

<style scoped></style>
